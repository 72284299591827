import makeStyles from "../../utils/makeStyles";

const HEADER_HEIGHT = 40;

export default makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: theme.palette.primary.light,
    borderRadius: 15,
    border: `1px solid ${theme.palette.grey[600]}`,
    margin: `${HEADER_HEIGHT / 2 + 30}px auto 20px auto`,
    width: "80%",
  },
  header: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: theme.palette.primary.main,
    borderRadius: "100px",
    width: "fit-content",
    height: HEADER_HEIGHT,
    marginTop: (HEADER_HEIGHT / 2) * -1,
    paddingLeft: 40,
    paddingRight: 40,
  },
  headerIcon: {
    marginRight: 5,
    width: "2.3vh",
    height: "2.3vh",
    color: theme.palette.primary.contrastText,
  },
  headerText: {
    color: theme.palette.primary.contrastText,
  },
  content: {
    padding: "20px 0",
    fontSize: "3vmin",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
}));
