import React from "react";
import { Avatar, Typography } from "@material-ui/core";

import useStyles from "./ProfileStyle";

const Profile: React.FC<{ nickname: string; profileImage: string }> = ({
  nickname,
  profileImage,
}) => {
  const classes = useStyles();

  return (
    <React.Fragment>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
        }}
      >
        <Avatar alt={nickname} src={profileImage} className={classes.avatar} />
        <div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography variant="h5">{nickname}</Typography>
            <Typography variant="h6">님</Typography>
          </div>
          <div>
            <Typography variant="h6">안녕하세요.</Typography>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Profile;
