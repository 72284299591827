import React, { CSSProperties } from "react";
import "./IconPointer.css";
import { Typography } from "@material-ui/core";
import useStyles from "./IconPointerStyle";

interface IIconPointer {
  position: "topLeft" | "topRight" | "bottomLeft" | "bottomRight";
  icon?: JSX.Element;
  iconImage?: string;
  title: string;
  description?: string | JSX.Element;
  height?: any;
}

const IconPointer: React.FC<IIconPointer> = ({
  position,
  icon,
  iconImage,
  title,
  description,
  height,
}) => {
  const classes = useStyles();
  const lineStyle: CSSProperties = { height };
  if (position.endsWith("Right")) {
    lineStyle.marginRight = 19;
  } else {
    lineStyle.marginLeft = 19;
  }

  const edgeStyle: CSSProperties = {};
  if (position.endsWith("Right")) {
    edgeStyle.marginRight = 16;
  } else {
    edgeStyle.marginLeft = 16;
  }

  const pointerImages = [
    <div
      key="pointer_1"
      className="home_oj_O_1"
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {icon}
    </div>,
    <div key="pointer_2" className="home_oj_O_2" style={lineStyle} />,
    <div key="pointer_3" className="home_oj_O_3" style={edgeStyle} />,
  ];

  const containerStyle: CSSProperties = {
    zIndex: 1300,
    position: "absolute",
    top: position.startsWith("top") ? 7 : undefined,
    bottom: position.startsWith("bottom") ? 7 : undefined,
    left: position.endsWith("Left") ? 16 : undefined,
    right: position.endsWith("Right") ? 16 : undefined,
  };
  const descriptionStyle: CSSProperties = {
    marginTop: "5px",
    marginBottom: "10px",
  };

  let _description: string | JSX.Element | undefined;
  if (typeof description === "string") {
    console.log("ui description string", description);
    _description = (
      <Typography
        paragraph={true}
        variant="h6"
        align={position.endsWith("Left") ? "left" : "right"}
        className={classes.contrastText}
      >
        {description}
      </Typography>
    );
  } else {
    console.log("ui description element", description);
    _description = description;
  }

  const children = [
    <div
      key="ui_pointer_image"
      className="home_oj_O"
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: position.endsWith("Left") ? "flex-start" : "flex-end",
      }}
    >
      {position.startsWith("bottom") ? pointerImages.reverse() : pointerImages}
    </div>,
    <div key="ui_description" style={descriptionStyle}>
      <Typography
        variant="h5"
        color="error"
        align={position.endsWith("Left") ? "left" : "right"}
      >
        {title}
      </Typography>
      {_description}
    </div>,
  ];

  return (
    <div className="help_help help_left" style={containerStyle}>
      {position.startsWith("bottom") ? children.reverse() : children}
    </div>
  );
};

export default IconPointer;
