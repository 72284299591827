import React, { useState, useContext } from "react";
import {
  IconButton,
  Typography,
  useTheme,
  useMediaQuery,
  Dialog,
  Button,
  Checkbox,
  FormControlLabel,
} from "@material-ui/core";
import { ArrowForwardIosRounded, Check, Close } from "@material-ui/icons";

import useStyles from "./TermsAndDialogStyle";
import { ConfigContext } from "../../AppContexts";
import TermsViewerDrawer from "../TermsViewerDrawer";

import "./termsStyle.css";

interface IDetailDialog {
  onAgree: () => void;
  onClose: () => void;
  open: boolean;
}

const TermsAndConditionsDialog: React.FC<IDetailDialog> = ({
  onAgree,
  onClose,
  open,
}) => {
  const {
    app_name,
    assets: { logo_img2 },
    terms_and_conditions,
  } = useContext(ConfigContext);
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("xl"));
  const [terms, setTerms] = useState({ open: false, url: "" });
  const [termCheckedList, setTermCheckedList] = useState(
    terms_and_conditions.map(() => false)
  );

  const checkTerm = (i: number) => () => {
    const checked = termCheckedList[i];
    setTermCheckedList((oldList) => {
      const newList = [...oldList];
      newList[i] = !checked;
      return newList;
    });
  };

  const checkAll = () => {
    const checked = termCheckedList.findIndex((c) => !c) >= 0;
    setTermCheckedList(termCheckedList.map(() => checked));
  };

  const isAllChecked = () => termCheckedList.findIndex((c) => !c) < 0;

  const onConfirm = () => {
    onAgree();
    onClose();
  };

  return (
    <Dialog fullScreen={fullScreen} open={open} onClose={onClose}>
      <div className={classes.termsDialogContainer}>
        <div className={classes.buttonContainer}>
          <IconButton
            size="small"
            className={classes.closeIconButton}
            onClick={onClose}
          >
            <Close className={classes.buttonIcon} />
          </IconButton>
        </div>

        <div className={classes.logoContainer}>
          <img src={logo_img2} alt={app_name} className={classes.logoImage} />
        </div>

        <div className={classes.titleContainer}>
          <Typography variant="h4" className={classes.title}>
            약관동의
          </Typography>
        </div>
        <div className="terms-contents">
          <div className={classes.subTitleContainer}>
            <Typography>
              서비스 이용을 위해 아래 약관에 동의해 주세요.
            </Typography>
          </div>

          <div className={classes.termsContainer}>
            <div className={classes.termContainer}>
              <div className={classes.checkFormContainer}>
                <FormControlLabel
                  label="전체 약관에 동의합니다."
                  className={classes.checkAll}
                  control={
                    <Checkbox
                      onClick={checkAll}
                      checked={isAllChecked()}
                      icon={
                        <Check
                          className={`${classes.buttonIcon} ${classes.whiteButtonIcon} ${classes.uncheckedCircle}`}
                        />
                      }
                      checkedIcon={
                        <Check
                          className={`${classes.buttonIcon} ${classes.blackButtonIcon} ${classes.checkedCircle}`}
                        />
                      }
                    />
                  }
                />
              </div>
            </div>

            {terms_and_conditions.map(({ title, isRequired, detailUrl }, i) => (
              <div key={`terms-${i}`} className={classes.termContainer}>
                <div className={classes.checkFormContainer}>
                  <FormControlLabel
                    label={title}
                    className={classes.check}
                    control={
                      <Checkbox
                        onClick={checkTerm(i)}
                        checked={termCheckedList[i]}
                        icon={
                          <Check
                            className={`${classes.buttonIcon} ${classes.greyButtonIcon}`}
                          />
                        }
                        checkedIcon={
                          <Check
                            className={`${classes.buttonIcon} ${classes.blackButtonIcon}`}
                          />
                        }
                      />
                    }
                  />
                  {isRequired && (
                    <Typography className={classes.required}>(필수)</Typography>
                  )}
                </div>
                {detailUrl && (
                  <IconButton
                    className={classes.closeIconButton}
                    onClick={() => setTerms({ open: true, url: detailUrl })}
                  >
                    <ArrowForwardIosRounded className={classes.ArrowIcon} />
                  </IconButton>
                )}
              </div>
            ))}

            <Button
              onClick={onConfirm}
              disabled={!isAllChecked()}
              className={classes.confirmButton}
            >
              가입완료
            </Button>
          </div>
        </div>
      </div>

      <TermsViewerDrawer
        open={terms.open}
        onClose={() => setTerms({ open: false, url: "" })}
        termsUrl={terms.url}
      />
    </Dialog>
  );
};

export default TermsAndConditionsDialog;
