import makeStyles from "../../utils/makeStyles";

export default makeStyles((theme) => ({
  rootContainer: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  todayTimestamp: theme.custom.message.todayTimestamp,
  messageTimestamp: theme.custom.message.messageTimestamp,
  beforeMessageContainer: theme.custom.message.beforeContainer,
  afterMessageContainer: theme.custom.message.afterContainer,
  initialMenuButtonText: {
    fontWeight: 700,
    fontSize: "14px",
  },
  avatarName: theme.custom.avatarName,
  otherConversationIcon: {
    width: 25,
    height: 25,
    marginRight: "5px",
    color: theme.palette.secondary.main,
  },
  messageListContainer: {
    display: "flex",
    flex: 1,
    minHeight: "100vh",
    width: "100%",
    backgroundColor: theme.palette.primary.light,
    paddingTop: 40,
  },
  messageList: {
    flex: 1,
    marginTop: 60,
    marginBottom: 60,
  },
  fab: {
    bottom: "60px",
    zIndex: 1,
    height: 35,
    padding: "5px 10px",
    border: "2px solid black",
    boxShadow: "5px 5px 8px 1px rgba(0, 0, 0, .2)",
    color: theme.palette.common.white,
    backgroundColor: theme.palette.common.black,
    "&:hover": {
      backgroundColor: theme.palette.common.black,
    },
  },
  fabOnMenuCollapsed: {
    position: "fixed",
    left: "calc(100% - 145px)",
  },
  fabOnMenuExpanded: {
    position: "relative",
    left: "calc(100% - 145px)",
  },
  answerLinkButton: {
    borderRadius: "3vmin",
    background: "linear-gradient(#0c16d5, #253bfe);",
    color: "#fff",
    fontSize: "7vmin",
    fontWeight: 900,
    marginBottom: 65,
    alignSelf: "center",
  },
}));
