import React, { useContext } from "react";

import { ProfileContext } from "../../AppContexts";
import { IChoice, ISimilarQuestion } from "../../utils/messages";
import ChoiceButton from "../ChoiceButton";
import LoginMessage from "./LoginMessage";
import { Search } from "@material-ui/icons";
import gaTraceData from "../../utils/ga";

interface IQuickResponse {
  description?: string;
  choices: IChoice[];
  onSelectChoice: (choice: IChoice) => void;
  onSelectChoiceWithoutInference: (choice: IChoice) => void;
  showEndSceneButton?: false;
  onEndScene?: () => void;
  loginRequired?: boolean;
  similarQuestions?: ISimilarQuestion[];
}

const ChoicesMessage: React.FC<IQuickResponse> = ({
  description,
  choices,
  onSelectChoice,
  onSelectChoiceWithoutInference,
  showEndSceneButton,
  onEndScene,
  loginRequired,
  similarQuestions = [],
}) => {
  const { userProfile } = useContext(ProfileContext);
  const isLoggedIn = !!userProfile;
  console.log("CHOICE message login required", loginRequired);
  console.log("CHOICE message similar questions", similarQuestions);

  // @ts-ignore
  const descComp = description && (
    <div dangerouslySetInnerHTML={{ __html: description }} />
  );

  if (loginRequired && !isLoggedIn) {
    return <LoginMessage />;
  }

  const _onSelectChoice = (choice: IChoice) => {
    gaTraceData(userProfile, "시나리오", choice.text, choice);
    onSelectChoice(choice);
  };

  const _onSelectChoiceWithoutInference = (choice: IChoice) => {
    console.log("111");
    gaTraceData(userProfile, "시나리오", choice.text, choice);
    onSelectChoiceWithoutInference(choice);
  };

  return (
    <React.Fragment>
      <div
        className="rce-mbox-text"
        style={{ display: "flex", flexDirection: "column" }}
      >
        {descComp}
        <div className="rce-mbox-buttons">
          {choices.map((c, i) => (
            <ChoiceButton
              key={`choice-${i + 1}`}
              choice={c}
              badgeContent={i + 1}
              onSelectChoice={_onSelectChoice}
            />
          ))}
          {similarQuestions.length > 0 && (
            <div>
              {(descComp || choices) && (
                <hr
                  style={{
                    backgroundColor: "#eeeeee",
                    height: 1,
                    border: 0,
                    margin: "15px 0",
                  }}
                ></hr>
              )}
              <span>
                <Search className="search-icon" /> 혹시 이런 내용이
                궁금하셨나요?
              </span>
              <div className="rce-mbox-buttons">
                {similarQuestions.map(({ displayName }, i) => (
                  <ChoiceButton
                    key={`similar-question-${i}`}
                    choice={{ text: displayName }}
                    badgeContent={String.fromCharCode(i + "A".charCodeAt(0))}
                    onSelectChoice={_onSelectChoiceWithoutInference}
                  />
                ))}
              </div>
            </div>
          )}
        </div>
        {showEndSceneButton && <div onClick={onEndScene}>상담종료버튼</div>}
      </div>
    </React.Fragment>
  );
};

export default ChoicesMessage;
