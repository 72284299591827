const crypto = require("crypto");

export function decryptAnswer(messageDataObj: any) {
  let text =
    "res" === messageDataObj.mtype
      ? messageDataObj.answerInfo
        ? messageDataObj.answerInfo.answer
        : ""
      : messageDataObj.messaging
      ? messageDataObj.messaging.m
      : "";

  if (!text) {
    return null;
  }

  // let key = 'tester1234567890';
  let key = "tester1234567890tester1234567890";
  let iv = "tester1234567890";
  // var cipher = crypto.createCipheriv('aes-128-cbc',key, iv);
  // var encipheredContent = cipher.update(text,'utf8','base64');
  // encipheredContent += cipher.final('base64');
  // console.log(encipheredContent);
  let decipher = crypto.createDecipheriv("aes-256-cbc", key, iv);
  let decipheredPlaintext = decipher.update(text, "base64", "utf8");
  decipheredPlaintext += decipher.final("utf8");
  console.log("decipheredPlaintext", decipheredPlaintext);

  return "res" === messageDataObj.mtype
    ? (messageDataObj.answerInfo.answer = decipheredPlaintext)
    : (messageDataObj.messaging.m = decipheredPlaintext);
}

// TODO 서버API 에서 응답 메시지 형태 출력하도록
const getMessageType = (m: any) => {
  if (
    m.messageType === "card" ||
    (m.answerJson &&
      m.answerJson.aTypeLow &&
      m.answerJson.aTypeLow.endsWith("Carousel"))
  ) {
    return "card";
  } else if (
    m.answerInfo &&
    m.answerInfo.objectiveList &&
    "1" in m.answerInfo.objectiveList
  ) {
    return "quickResponse";
  } else {
    return m.messageType || "html";
  }
};

export interface IChoice {
  text: string;
  value?: any;
}
export interface ISimilarQuestion {
  rate: number;
  displayName: string;
  idx: string;
}
interface IMessageCallbacks {
  printSentMessage: (msg: string) => void;
  submitMessage: (msg: string) => void;
  submitMessageWithoutInference: (msg: string) => void;
  onEndScene: () => void;
  onCardClick: (msg: string | undefined, c: any | undefined) => void;
  showInitialMenu: () => void;
  deleteAccount: () => void;
  openInModal: (c: IChoice) => void;
}
const quickResponse = (
  printSentMessage: (msg: string) => void,
  submitMessage: (msg: string) => void
) => (c: IChoice) => {
  printSentMessage(c.text);
  submitMessage(c.text);
};
const quickResponseWithoutInference = (
  printSentMessage: (msg: string) => void,
  submitMessageWithoutInference: (msg: string) => void
) => (c: IChoice) => {
  printSentMessage(c.text);
  submitMessageWithoutInference(c.text);
};
const openInNewTab = (c: IChoice) => window.open(c.value, "_blank");

// TODO 병원명과 봇이름은 서버에서 전달해야 함.
// TODO 사용자명은 대화를 주고받을 때 사용자 아이디 혹은 닉네임을 함께 전달해야 하고 서버에서 메시지에 포함해야 함.
const replaceKeywords = (
  str: string,
  appName: string,
  csName: string,
  userName: string
) => {
  if (!str) {
    return str;
  } else {
    return str
      .replace("{{hospital}}", appName)
      .replace("{{botName}}", csName)
      .replace("{{name}}", userName);
  }
};

export function convertToChatMessage(
  messageObj: any,
  detailList: any[],
  callbacks: IMessageCallbacks,
  appName: string,
  csName: string,
  userName: string
): { msgBox: any; detailImg: string; afterMessageCallback: () => void } {
  const type = getMessageType(messageObj);
  let resMessage = {};
  let afterMessageCallback: () => void = () => null;
  let detailImg = "";
  const {
    printSentMessage,
    submitMessage,
    submitMessageWithoutInference,
    onEndScene,
    onCardClick,
    showInitialMenu,
    deleteAccount,
    openInModal,
  } = callbacks;
  console.log("message type", type);

  const showReportWrongAnswerButton =
    messageObj.answerInfo &&
    (messageObj.answerInfo.step === "E" ||
      messageObj.answerInfo.isScenario === "N") &&
    messageObj.answerInfo.rate < 99;
  const showInitialMenuButton =
    messageObj.status === "NO_DATA" ||
    showReportWrongAnswerButton ||
    (messageObj.answerInfo &&
      (messageObj.answerInfo.step === "E" ||
        messageObj.answerInfo.isScenario === "N"));
  const showFab =
    messageObj.answerInfo && messageObj.answerInfo.isScenario === "Y";
  const modifiedAnswer =
    messageObj.notEncrypted && messageObj.answerInfo.answer
      ? messageObj.answerInfo.answer
      : replaceKeywords(decryptAnswer(messageObj), appName, csName, userName);

  let onSelectChoice = quickResponse(printSentMessage, submitMessage);
  const onSelectChoiceWithoutInference = quickResponseWithoutInference(
    printSentMessage,
    submitMessageWithoutInference
  );
  if (
    messageObj.answerJson &&
    messageObj.answerJson.aType === "EXTERNAL_LINK"
  ) {
    onSelectChoice = openInNewTab;
  } else if (
    messageObj.answerJson &&
    messageObj.answerJson.aType === "MODAL_LINK"
  ) {
    onSelectChoice = openInModal;
  }

  console.log(
    "sorted similar questions",
    type,
    messageObj.answerSortedInfoList
  );

  if (type === "quickResponse") {
    console.log(
      "SHOW INITIAL MENU BUTTON",
      messageObj.status === "NOT_UNDERSTAND_RESPONSE"
    );

    resMessage = {
      type,
      ...messageObj,
      text: modifiedAnswer,
      choices: messageObj.answerInfo.choices
        ? messageObj.answerInfo.choices.map((c: any) => ({
            ...c,
            answerInfo: messageObj.answerInfo,
          }))
        : Object.values(messageObj.answerInfo.objectiveList).map((o) => ({
            text: o,
            answerInfo: messageObj.answerInfo,
          })),
      showEndSceneButton: messageObj.status === "NOT_UNDERSTAND_RESPONSE",
      showFab,
      onSelectChoice,
      onSelectChoiceWithoutInference,
      onEndScene,
      similarQuestions:
        messageObj.answerSortedInfoList &&
        messageObj.answerSortedInfoList.map((c: any) => ({
          ...c,
          answerInfo: messageObj.answerInfo,
        })),
    };
  } else if (type === "card") {
    let cardContents = messageObj.cardContents || [];

    console.log("card message", messageObj);

    if (
      messageObj.answerJson &&
      messageObj.answerJson.aTypeLow &&
      messageObj.answerJson.aTypeLow.endsWith("Carousel")
    ) {
      const filterdCards = detailList.filter(
        (e) => e.aTypeLow === messageObj.answerJson.aTypeLow
      );
      console.log("carousel cards", filterdCards);
      cardContents = filterdCards.map((c) => {
        if (c.btnInfo[1].btnTitle === "answerCode") {
          return { imgSrc: c.answerImgUrl, card: c };
        } else {
          return {
            imgSrc: c.answerImgUrl,
            card: c,
            response: c.btnInfo[0].btnValue,
          };
        }
      });
    }

    resMessage = {
      type,
      isSystemMessage: true,
      showFab,
      ...messageObj,
      cardContents,
      onCardClick,
    };
  } else if (type === "reactNode") {
    console.log(
      "reactNode sorted similar questions",
      messageObj.answerSortedInfoList
    );

    resMessage = {
      type,
      ...messageObj,
      showFab,
      onSelectChoiceWithoutInference,
      showInitialMenuButton,
      showReportWrongAnswerButton,
      similarQuestions: messageObj.answerSortedInfoList,
    };
  } else if (type === "html") {
    console.log(
      "html sorted similar questions",
      messageObj.answerSortedInfoList
    );

    resMessage = {
      type,
      ...messageObj,
      showFab,
      html: messageObj.html || modifiedAnswer,
      onSelectChoiceWithoutInference,
      showInitialMenuButton,
      showReportWrongAnswerButton,
      similarQuestions: messageObj.answerSortedInfoList,
    };
  }

  if (
    !messageObj.notEncrypted &&
    messageObj.answerJson &&
    messageObj.answerJson.answerCode
  ) {
    console.log("answer code", messageObj.answerJson.answerCode);
    console.log(
      "matched detail",
      detailList.find((e) => e.answerCode === messageObj.answerJson.answerCode)
    );
    const detailAnswer = detailList.find(
      (e) => e.answerCode === messageObj.answerJson.answerCode
    );

    if (detailAnswer && detailAnswer.answerImgUrl) {
      detailImg = detailAnswer.answerImgUrl;
    } else if (detailAnswer.answerCode === "greetMn") {
      afterMessageCallback = showInitialMenu;
    } else if (messageObj.answerJson.answerCode === "User_drop_pop") {
      // TODO 사용자 삭제처리를 서버로 이동
      afterMessageCallback = deleteAccount;
    }
  }

  return { msgBox: resMessage, detailImg, afterMessageCallback };
}
