import React from "react";
import { Typography, Button, Modal } from "@material-ui/core";

import useStyles from "./AlertModalStyle";

interface IAlertDialog {
  onCancel?: () => void;
  cancelTitle?: string;
  cancelTitleStyle?: object;
  onConfirm?: () => void;
  confirmTitle?: string;
  confirmTitleStyle?: object;
  onClose?: () => void;
  open: boolean;
  title?: string;
  messages?: string[] | string;
}

const AlertModal: React.FC<IAlertDialog> = ({
  onCancel,
  cancelTitle = "취소",
  cancelTitleStyle,
  onConfirm,
  confirmTitle = "확인",
  confirmTitleStyle,
  onClose,
  open,
  title,
  messages,
}) => {
  const classes = useStyles();
  const _messages = Array.isArray(messages) ? messages : [messages];

  return (
    <Modal open={open} onClose={onClose} disableEnforceFocus disableAutoFocus>
      <div className={classes.modalContainer}>
        <div className={classes.txtContents}>
          {title && (
            <div className={classes.modalTitle}>
              <Typography style={{ fontSize: "17px" }}>{title}</Typography>
            </div>
          )}

          <div className={classes.modalContent}>
            {_messages.map((m, i) => (
              <Typography key={`message-${i}`} style={{ fontSize: "14px" }}>
                {m}
              </Typography>
            ))}
          </div>
        </div>

        <div className={classes.modalFooter}>
          {onCancel && (
            <Button
              onClick={onCancel}
              className={classes.modalButton}
              style={cancelTitleStyle}
            >
              {cancelTitle}
            </Button>
          )}
          {onConfirm && (
            <Button
              onClick={onConfirm}
              className={classes.modalButton}
              style={confirmTitleStyle}
            >
              {confirmTitle}
            </Button>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default AlertModal;
