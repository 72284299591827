import React, { useContext } from "react";
import { AppBar, Toolbar, IconButton, Avatar } from "@material-ui/core";
import { Home as HomeIcon, Person } from "@material-ui/icons";

import { ConfigContext, ProfileContext } from "../../AppContexts";
import useStyles from "./MenuBarStyle";

interface IMenuBar {
  style?: object;
  className?: string;
  onHomeClick: (e: React.FormEvent) => void;
  onProfileClick: (e: React.FormEvent) => void;
}

const MenuBar: React.FC<IMenuBar> = ({
  style,
  className,
  onHomeClick,
  onProfileClick,
}) => {
  const {
    app_name,
    assets: { logo_img },
  } = useContext(ConfigContext);
  const { userProfile } = useContext(ProfileContext);
  const classes = useStyles();

  return (
    <React.Fragment>
      <AppBar
        position="fixed"
        style={style}
        className={`${classes.root} ${className}`}
      >
        <Toolbar className={classes.toolBar}>
          <IconButton
            edge="start"
            color="secondary"
            aria-label="menu"
            className={classes.iconButton}
            onClick={onHomeClick}
          >
            <HomeIcon className={classes.icon} />
          </IconButton>
          <a href="/" title="새로고침">
            <img src={logo_img} height={30} alt={app_name} />
          </a>
          <IconButton
            edge="end"
            color="secondary"
            aria-label="settings"
            className={classes.iconButton}
            style={{ justifySelf: "flex-end" }}
            onClick={onProfileClick}
          >
            {userProfile ? (
              <Avatar
                alt={userProfile.nickname}
                src={userProfile.profileImage}
                className={classes.avatar}
              />
            ) : (
              <Person className={classes.icon} />
            )}
          </IconButton>
        </Toolbar>
      </AppBar>
    </React.Fragment>
  );
};

export default MenuBar;
