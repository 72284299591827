import React, { useState, useContext } from "react";
import { Backdrop, Button, Typography } from "@material-ui/core";
import { Home as HomeIcon, Person } from "@material-ui/icons";
import { useCookies } from "react-cookie";

import { ConfigContext } from "../../AppContexts";
import useStyles from "./TutorialOverlayStyle";
import IconPointer from "./IconPointer";
import ChatInputForm from "../ChatInputForm";

const TutorialOverlay: React.FC = () => {
  const { cs_name_english } = useContext(ConfigContext);
  const classes = useStyles();
  const [open, setOpen] = useState(true);
  const [, setCookie] = useCookies(["tutorialCompleted"]);

  const homeIcon = <HomeIcon className={classes.icon} />;
  const personIcon = <Person className={classes.icon} />;
  const menuDescription = (
    <Typography className={classes.contrastText} variant="h6">
      {cs_name_english}가 제공하는
      <br />
      모든 정보를 <br />
      알려드립니다.
    </Typography>
  );
  const hashIcon = <div className={classes.hashIcon}>#</div>;
  const chatDescription = (
    <Typography className={classes.contrastText} align="right" variant="h6">
      무엇이든 물어보세요.
      <br />
      {cs_name_english}가 답변해 드립니다.
    </Typography>
  );

  const handleClose = () => {
    setCookie("tutorialCompleted", true, { path: "/" });
    setOpen(false);
  };

  return (
    <Backdrop open={open} className={classes.root}>
      <IconPointer
        position="topLeft"
        title="처음메뉴"
        description="처음메뉴를 열 수 있습니다."
        icon={homeIcon}
      />
      <IconPointer
        position="topRight"
        title="프로필"
        description="로그인 정보를 확인할 수 있습니다."
        icon={personIcon}
        height="20vh"
      />
      <IconPointer
        position="bottomLeft"
        title="모든 서비스 보기"
        description={menuDescription}
        icon={hashIcon}
      />
      <IconPointer
        position="bottomRight"
        title="채팅창"
        description={chatDescription}
      />

      <Button
        className={classes.button}
        variant="contained"
        color="secondary"
        onClick={handleClose}
      >
        <Typography variant="subtitle1" className={classes.buttonTextSecondary}>
          {cs_name_english}
        </Typography>
        <Typography variant="subtitle2">와 대화하러 </Typography>
        <Typography variant="subtitle2" className={classes.buttonTextSecondary}>
          {" "}
          GO!
        </Typography>
      </Button>

      <ChatInputForm
        placeholder="질문을 입력하세요."
        className={classes.chatInput}
      />
    </Backdrop>
  );
};

export default TutorialOverlay;
