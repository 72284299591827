import makeStyles from "../../utils/makeStyles";

export default makeStyles((theme) => ({
  helpChatOj: {
    height: "44px",
    margin: "4px 0 4px 10px",
    borderRadius: "100px",
    backgroundColor: "#fff",
    display: "flex",
    flexDirection: "row",
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    paddingLeft: "20px",
    paddingRight: "8px",
    boxShadow: "none",
  },
  helpChatOjP: {
    flex: 1,
    display: "table-cell",
    verticalAlign: "middle",
    fontWeight: 600,
    fontSize: "16px",
    width: "calc(100vw - 150px)",
  },
  helpChatSendButton: {
    color: theme.palette.secondary.contrastText,
    backgroundColor: theme.palette.text.secondary,
    width: "30px",
    height: "30px",
    padding: 0,
    transform: "rotate(-45deg)",
    zIndex: theme.zIndex.tooltip,
    "&:hover": {
      backgroundColor: theme.palette.text.secondary,
    },
    "& > span > svg": {
      fontSize: "16px",
      marginRight: "-2px",
    },
  },
}));
