import React from "react";

const defaultAssets: any = {};
const defaultWorkTime: any = {};
export const ConfigContext = React.createContext({
  cid: "",
  ip: "127.0.0.1",
  apiRoot: "127.0.0.1",
  gatewayRoot: "127.0.0.1",
  app_name: "",
  cs_name: "",
  cs_name_post_position: "가",
  cs_name_english: "",
  assets: defaultAssets,
  terms_and_conditions: [],
  workTime: defaultWorkTime,
});

// userProfile = {nickname, profileImage: thumbnail_image_url};
const userProfile: any = null;
const setUserProfile: (profile: any) => void = () => null;
export const ProfileContext = React.createContext({
  userProfile,
  setUserProfile,
});

export const UIContext = React.createContext({
  showPostMessageComponents: true,
});
