import React, { useState } from "react";
import { RouteComponentProps } from "react-router";
import { Button, Typography } from "@material-ui/core";

import TermsAndConditionsDialog from "../components/TermsAndConditionsDialog";

interface Props extends RouteComponentProps {}

const WiseDev: React.FC<Props> = (props) => {
  const [showTermsDialog, setShowTermsDialog] = useState(false);

  return (
    <div>
      <Button onClick={() => setShowTermsDialog(true)}>
        <Typography>약관동의화면</Typography>
      </Button>
      <TermsAndConditionsDialog
        open={showTermsDialog}
        onAgree={() => null}
        onClose={() => setShowTermsDialog(false)}
      />
    </div>
  );
};

export default WiseDev;
