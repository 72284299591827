import makeStyles from "../../utils/makeStyles";

export default makeStyles((theme) => ({
  termsDialogContainer: {
    position: "relative",
  },
  buttonContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    paddingTop: 5,
    paddingRight: 5,
  },
  closeIconButton: {
    color: theme.palette.common.black,
  },
  logoContainer: {
    height: 48,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  logoImage: {
    height: "80%",
  },
  titleContainer: {
    backgroundColor: theme.palette.primary.main,
    height: "15vh",
    minHeight: 80,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  subTitleContainer: {
    textAlign: "center",
    margin: "5.5vmin 0 3vmin 0",
    fontSize: "4vmin",
    "& > p": {
      fontSize: "4vmin",
      fontWeight: 700,
    },
  },
  title: {
    color: theme.palette.primary.contrastText,
  },
  termsContainer: {
    width: "80%",
    margin: "0 auto",
  },
  termContainer: {
    margin: "4vmin 0",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  checkFormContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  checkAll: {
    marginLeft: -5,
    marginRight: 0,
    "& > span": {
      fontSize: "3vmin",
      fontWeight: 700,
      marginLeft: 5,
    },
  },
  check: {
    marginRight: 0,
    padding: 0,
    "& > span": {
      fontSize: "3vmin",
      marginLeft: 10,
    },
  },
  uncheckedCircle: {
    borderRadius: 20,
    margin: "5px 0 5px -5px",
    backgroundColor: theme.palette.grey[400],
    padding: 5,
  },
  checkedCircle: {
    borderRadius: 20,
    margin: "5px 0 5px -5px",
    backgroundColor: theme.palette.secondary.main,
    padding: 5,
  },
  noBackground: {
    width: 10,
    height: 10,
    borderRadius: 20,
    margin: 5,
    color: theme.palette.grey[400],
  },
  buttonIcon: {
    width: 30,
    height: 30,
  },
  blackButtonIcon: {
    color: theme.palette.grey[800],
  },
  greyButtonIcon: {
    color: theme.palette.grey[400],
  },
  whiteButtonIcon: {
    color: theme.palette.common.white,
  },
  agreeText: {
    fontSize: "3vmin",
    margin: "0 10px",
  },
  required: {
    color: "#f00",
    fontSize: "3vmin",
  },
  ArrowIcon: {
    fontSize: "14px",
  },
  confirmButton: {
    width: "100%",
    fontSize: "5vmin",
    fontWeight: 700,
    margin: "2vmin",
    color: theme.palette.common.white,
    backgroundColor: theme.palette.grey[800],
    "&:disabled": {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.grey[400],
    },
    "&:hover": {
      backgroundColor: theme.palette.grey[700],
    },
  },
}));
