import ReactGA from "react-ga";
import moment from "moment";

const deviceName = () => {
  if (/Android/i.test(window.navigator.userAgent)) {
    return "안드로이드";
  } else if (/iPhone|iPad|iPod/i.test(window.navigator.userAgent)) {
    return "아이폰";
  } else {
    return "PC포함 그 외 디바이스";
  }
};

export default function gaTraceData(
  userProfile: any,
  category: string,
  action: string,
  menu: any | undefined
) {
  // const devUrl = ["localhost", "dev.d1i54h51euezcf.amplifyapp.com", "release.d1i54h51euezcf.amplifyapp.com"]; //로컬, 개발서버 URL
  const devUrl = [""]; //로컬, 개발서버 URL
  const hostName = window.location.hostname;

  if (devUrl.includes(hostName)) {
    return;
  }

  const evnetTime =
    moment().format("LL dddd") + " " + moment().format("a hh:mm");
  const { qustId1, qustId2, qustId3, menuCode } = menu || {};
  let labelInfo = "";
  if (qustId1 || qustId2 || qustId3 || menuCode) {
    labelInfo = `qid1: ${qustId1 || "없음"}, qid2: ${
      qustId2 || "없음"
    }, qid3: ${qustId3 || "없음"}, menuCode: ${menuCode}`;
  }
  let userLoginInfo = "비로그인";
  let label = "";

  if (userProfile) {
    const { nickname, birthday, gender, tel } = userProfile; // tel 현재 없는 값
    userLoginInfo = `[이름: ${nickname}, 전화번호: ${tel}, 성별: ${gender}, 생년월일: ${birthday}`;
    label = `(${evnetTime}) ${labelInfo} 유저로그인정보: ${userLoginInfo}, 디바이스정보: ${deviceName()}`;
  }

  console.log("GA event", { category, action, label });

  ReactGA.event({ category, action, label });
}
