import React from "react";
import { IconButton, Paper, InputBase } from "@material-ui/core";
import { Send } from "@material-ui/icons";

import useStyles from "./ChatInputFormStyle";
// import {ReactComponent as Send} from "../../images2/send.svg";

interface ChatInputForm {
  className?: string;
  placeholder?: string;
  value?: string;
  onSubmit?: (e: React.FormEvent) => void;
  onInputChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onFocus?: () => void;
}

const ChatInputForm: React.FC<ChatInputForm> = ({
  className,
  placeholder,
  value,
  onInputChange,
  onFocus,
  onSubmit,
}) => {
  const classes = useStyles();

  return (
    <React.Fragment>
      <Paper
        component="form"
        className={`${classes.helpChatOj} ${className}`}
        onSubmit={onSubmit}
      >
        <InputBase
          className={classes.helpChatOjP}
          placeholder={placeholder}
          value={value}
          onChange={onInputChange}
          onFocus={onFocus}
        />
        <IconButton onClick={onSubmit} className={classes.helpChatSendButton}>
          <Send />
        </IconButton>
      </Paper>
    </React.Fragment>
  );
};

export default ChatInputForm;
