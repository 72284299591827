import React from "react";

interface Props {
  error: Error;
}
interface States {}
export default class ErrorPage extends React.Component<Props, States> {
  render(): React.ReactElement<any> {
    return (
      <div>
        <div className="page-header">
          <div>{this.props.error ? this.props.error.name : "Error"}</div>
          <p>{this.props.error && this.props.error.message}</p>
        </div>
      </div>
    );
  }
}
