import React, { useContext } from "react";
import { Typography, IconButton } from "@material-ui/core";

import useStyles from "./PostMessageButtonsStyle";
import { HomeOutlined, SentimentVeryDissatisfied } from "@material-ui/icons";
import { ProfileContext } from "../../AppContexts";
import gaTraceData from "../../utils/ga";
import "./PostMessageButtons.css";

export const WRONG_ANSWER_TEXT = "원하는 답변이 아니에요";

interface IMessageBox {
  showInitialMenu: () => void;
  showInitialMenuButton: boolean;
  reportWrongAnswer: (reqMessage: string | undefined, answerInfo: any) => void;
  showReportWrongAnswerButton: boolean;
  reqMessage: string | undefined;
  answerInfo: any;
}

const PostMessageButtons: React.FC<IMessageBox> = ({
  showInitialMenu,
  showInitialMenuButton,
  reportWrongAnswer,
  showReportWrongAnswerButton,
  reqMessage,
  answerInfo,
}) => {
  const classes = useStyles();
  const { userProfile } = useContext(ProfileContext);

  if (!showInitialMenuButton && !showReportWrongAnswerButton) {
    return null;
  }

  const onShowInitialMenuClick = () => {
    showInitialMenu();
    gaTraceData(userProfile, "시나리오", "메뉴 버튼 클릭", null);
  };

  const onReportWrongAnswerClick = () => {
    reportWrongAnswer(reqMessage, answerInfo);
    gaTraceData(userProfile, "시나리오", "잘못된 답변 보고 클릭", answerInfo);
  };

  return (
    <div className={classes.afterMessageContainer}>
      {showInitialMenuButton && (
        <IconButton
          onClick={onShowInitialMenuClick}
          className={classes.initialMenuButton}
        >
          <HomeOutlined fontSize="small" />
          <Typography className={`${classes.HomeButtonText} worng-buttons`}>
            메뉴
          </Typography>
        </IconButton>
      )}
      {showReportWrongAnswerButton && (
        <IconButton
          onClick={onReportWrongAnswerClick}
          className={classes.reportWrongAnswerButton}
        >
          {/*<HomeRounded fontSize="small" className={classes.reportWrongAnswerButtonIcon}/>*/}
          <SentimentVeryDissatisfied
            className={classes.reportWrongAnswerButtonIcon}
          />
          <Typography
            className={`${classes.reportWrongAnswerButtonText} worng-buttons`}
          >
            {WRONG_ANSWER_TEXT}
          </Typography>
        </IconButton>
      )}
    </div>
  );
};

export default PostMessageButtons;
