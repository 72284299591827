import React, { Component } from "react";
import "./MessageBox.css";

import { PhotoMessage, LocationMessage } from "react-chat-elements";

import { FaForward } from "react-icons/fa";

import classNames from "classnames";
import ChoicesMessage from "./ChoicesMessage";
import MenuCarousel from "../MenuCarousel";
import DefaultMessage from "./DefaultMessage";
import { UIContext } from "../../AppContexts";

export class MessageBox extends Component {
  static contextType = UIContext;

  componentWillReceiveProps(nextProps) {
    if (nextProps.focus !== this.props.focus && nextProps.focus === true) {
      if (this.refs["message"]) {
        this.refs["message"].scrollIntoView({
          block: "center",
          behavior: "smooth",
        });

        this.props.onMessageFocused(nextProps);
      }
    }
  }

  render() {
    var positionCls = classNames("rce-mbox", {
      "rce-mbox-right": this.props.position === "right",
    });
    var thatAbsoluteTime =
      this.props.type !== "text" &&
      this.props.type !== "file" &&
      !(this.props.type === "location" && this.props.text);

    console.log("message box login required", this.props.loginRequired);
    console.log("ui context from message box", this.context);

    return (
      <div
        ref="message"
        className={classNames("rce-container-mbox", this.props.className)}
        onClick={this.props.onClick}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          {this.props.avatar && (
            <img
              src={this.props.avatar}
              width={38}
              height={38}
              style={{ backgroundColor: "#fff", borderRadius: "100%" }}
              alt="아바타"
            />
          )}
          <div className={classNames("before-comp-container")}>
            {this.props.renderBeforeCmp instanceof Function &&
              this.props.renderBeforeCmp()}
          </div>
        </div>
        {
          <div
            className={
              this.props.isSystemMessage
                ? "system-message-root"
                : classNames(
                    positionCls,
                    { "rce-mbox--clear-padding": thatAbsoluteTime },
                    { "rce-mbox--clear-notch": !this.props.notch },
                    { "message-focus": this.props.focus },
                    this.props.isGreeting
                      ? "rce-mbox-greeting"
                      : "rce-mbox-normal"
                  )
            }
          >
            <div
              className={
                this.props.isSystemMessage
                  ? "system-message-body"
                  : "rce-mbox-body"
              }
              onContextMenu={this.props.onContextMenu}
            >
              {this.props.forwarded === true && (
                <div
                  className={classNames(
                    "rce-mbox-forward",
                    {
                      "rce-mbox-forward-center":
                        this.props.position === "center",
                    },
                    {
                      "rce-mbox-forward-right": this.props.position === "left",
                    },
                    { "rce-mbox-forward-left": this.props.position === "right" }
                  )}
                  onClick={this.props.onForwardClick}
                >
                  <FaForward />
                </div>
              )}

              {this.props.title && (
                <div
                  style={
                    this.props.titleColor && { color: this.props.titleColor }
                  }
                  onClick={this.props.onTitleClick}
                  className={classNames("rce-mbox-title", {
                    "rce-mbox-title--clear": this.props.type === "text",
                  })}
                >
                  {this.props.title && <span>{this.props.title}</span>}
                </div>
              )}

              {this.props.type === "text" && (
                <div className="rce-mbox-text">{this.props.text}</div>
              )}
              {this.props.type === "system" && (
                <div
                  style={{
                    flex: 1,
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  시스템메시지:
                  <div dangerouslySetInnerHTML={{ __html: this.props.html }} />
                </div>
              )}

              {(this.props.type === "html" ||
                this.props.type === "reactNode") && (
                <div className="rce-mbox-text">
                  <DefaultMessage
                    html={this.props.html}
                    messageNode={this.props.messageNode}
                    similarQuestions={this.props.similarQuestions}
                    onSelectChoiceWithoutInference={
                      this.props.onSelectChoiceWithoutInference
                    }
                    loginRequired={this.props.loginRequired}
                  />
                </div>
              )}

              {this.props.type === "quickResponse" && (
                <ChoicesMessage
                  similarQuestions={this.props.similarQuestions}
                  choices={this.props.choices}
                  onSelectChoice={this.props.onSelectChoice}
                  onSelectChoiceWithoutInference={
                    this.props.onSelectChoiceWithoutInference
                  }
                  description={this.props.text}
                  showEndSceneButton={this.props.showEndSceneButton}
                  onEndScene={this.props.onEndScene}
                  loginRequired={this.props.loginRequired}
                />
              )}

              {this.props.type === "card" && (
                <MenuCarousel
                  cardContents={this.props.cardContents}
                  layout={this.props.layout}
                  onCardClick={this.props.onCardClick}
                  cols={this.props.cols}
                  footerMessage={this.props.footerMessage}
                  category={this.props.category}
                />
              )}

              {this.props.type === "location" && (
                <LocationMessage
                  onOpen={this.props.onOpen}
                  data={this.props.data}
                  target={this.props.target}
                  href={this.props.href}
                  apiKey={this.props.apiKey}
                  src={this.props.src}
                  zoom={this.props.zoom}
                  markerColor={this.props.markerColor}
                  text={this.props.text}
                />
              )}

              {this.props.type === "photo" && (
                <PhotoMessage
                  onOpen={this.props.onOpen}
                  onDownload={this.props.onDownload}
                  onLoad={this.props.onLoad}
                  onPhotoError={this.props.onPhotoError}
                  data={this.props.data}
                  width={this.props.width}
                  height={this.props.height}
                  text={this.props.text}
                />
              )}
            </div>

            {this.props.notch &&
              (this.props.position === "right" ? (
                <svg
                  className={classNames("rce-mbox-right-notch", {
                    "message-focus": this.props.focus,
                  })}
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path d="M0 0v20L20 0" />
                </svg>
              ) : (
                <div>
                  <svg
                    className={classNames("rce-mbox-left-notch", {
                      "message-focus": this.props.focus,
                    })}
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <defs>
                      <filter id="filter1" x="0" y="0">
                        <feOffset
                          result="offOut"
                          in="SourceAlpha"
                          dx="-2"
                          dy="-5"
                        />
                        <feGaussianBlur
                          result="blurOut"
                          in="offOut"
                          stdDeviation="3"
                        />
                        <feBlend
                          in="SourceGraphic"
                          in2="blurOut"
                          mode="normal"
                        />
                      </filter>
                    </defs>
                    <path d="M20 0v20L0 0" filter="url(#filter1)" />
                  </svg>
                </div>
              ))}
          </div>
        }
        {this.props.isLastMessage && this.context.showPostMessageComponents && (
          <div className={classNames("after-comp-container")}>
            {this.props.renderAfterCmp instanceof Function &&
              this.props.renderAfterCmp()}
          </div>
        )}
      </div>
    );
  }
}

MessageBox.defaultProps = {
  position: "left",
  type: "text",
  text: "",
  title: null,
  titleColor: null,
  onTitleClick: null,
  onForwardClick: null,
  onReplyClick: null,
  onReplyMessageClick: null,
  date: new Date(),
  data: {},
  choices: [],
  onSelectChoice: () => null,
  onClick: null,
  onOpen: null,
  onDownload: null,
  onLoad: null,
  onPhotoError: null,
  forwarded: false,
  reply: false,
  status: null,
  dateString: null,
  notch: true,
  avatar: null,
  renderBeforeCmp: null,
  renderAfterCmp: null,
  copiableDate: false,
  onContextMenu: null,
  focus: false,
  onMessageFocused: null,
  renderMessage: () => null,
  isSystemMessage: false,
  layout: "spacing",
  footerMessage: null,
  showInitialMenuButton: false,
  similarQuestions: [],
  onCardClick: () => null,
  cols: 3,
  isGreeting: false,
};

export default MessageBox;
