import makeStyles from "../../utils/makeStyles";

export default makeStyles((theme) => ({
  root: theme.custom.menuBar,
  toolBar: {
    justifyContent: "space-between",
    alignItems: "center",
    boxShadow: "none",
    minHeight: theme.custom.menuBar.height,
  },
  iconButton: {
    color: theme.palette.primary.contrastText,
  },
  icon: {},
  avatar: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
}));
