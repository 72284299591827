import React from "react";
import { RouteComponentProps } from "react-router";

interface Props extends RouteComponentProps {}

const AnswerDetail: React.FC<Props> = (props) => {
  return <div>TODO: full screen modal</div>;
};

export default AnswerDetail;
