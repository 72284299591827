import makeStyles from "../../utils/makeStyles";

export default makeStyles((theme) => ({
  afterMessageContainer: theme.custom.message.afterContainer,
  initialMenuButton: {
    height: 35,
    padding: "5px 10px",
    border: "2px solid #464646;",
    backgroundColor: theme.palette.grey[800],
    color: theme.palette.info.contrastText,
    marginRight: "5px",
    "&:hover": {
      backgroundColor: theme.palette.grey[800],
    },
  },
  HomeButtonText: {
    color: theme.palette.common.white,
    fontSize: "14px",
    fontWeight: 700,
  },
  reportWrongAnswerButtonText: {
    color: theme.palette.grey[800],
    fontSize: "14px",
    fontWeight: 700,
  },
  reportWrongAnswerButton: {
    height: 35,
    padding: "5px 10px",
    backgroundColor: theme.palette.error.light,
    color: theme.palette.info.contrastText,
    border: "2px solid #464646",
    "&:hover": {
      backgroundColor: theme.palette.error.light,
    },
  },
  reportWrongAnswerButtonIcon: {
    color: theme.palette.error.dark,
  },
  HomeButtonIcon: {
    border: "2px solid #464646",
  },
}));
