import makeStyles from "../../utils/makeStyles";

export default makeStyles((theme) => ({
  appbar: {
    boxShadow: "none",
  },
  toolbar: {
    justifyContent: "center",
    paddingLeft: 0,
    paddingRight: 0,
    boxShadow: "none",
    padding: "50px 0 10px",
  },
  buttonArea: {
    display: "flex",
    flexDirection: "row",
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    justifyContent: "space-between",
    "& > :only-child": {
      marginLeft: "auto",
    },
  },
  closeIconButton: {
    color: theme.palette.common.white,
  },
  closeIcon: {
    width: "1em",
    height: "1em",
  },
  hr: {
    border: `1px solid ${theme.palette.grey[400]}`,
    width: "80%",
  },
  kakaoBanner: {
    width: "80%",
    margin: "10px auto",
    "& img": {},
  },
  kakaoBannerImg: {
    width: "100%",
    height: "auto",
    padding: "0 0 5vmin 0",
  },
  modalContainer: {
    top: "50%",
    left: "50%",
    transform: `translate(-50%, -50%)`,
    position: "absolute",
    backgroundColor: theme.palette.background.paper,
    borderRadius: "15px",
    boxShadow: theme.shadows[5],
  },
  modalContent: {
    backgroundColor: theme.palette.background.paper,
    minWidth: 300,
    minHeight: 100,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: 100,
    borderTopLeftRadius: "15px",
    borderTopRightRadius: "15px",
  },
  modalFooter: {
    height: 50,
    backgroundColor: theme.palette.grey[200],
    borderBottomLeftRadius: "15px",
    borderBottomRightRadius: "15px",
  },
  modalButton: {
    width: "50%",
    height: "100%",
    "&:first-child": {
      borderBottomLeftRadius: "15px",
      // color: theme.palette.info.main,
      color: "blue",
    },
    "&:last-child": {
      borderBottomRightRadius: "15px",
      // color: theme.palette.error.main,
      color: "red",
    },
  },
  loginContainer: {
    width: "80%",
    marginTop: 15,
  },
  loginTitle: {
    textAlign: "center",
    margin: "5px 0",
  },
}));
