import makeStyles from "../../utils/makeStyles";

export default makeStyles((theme) => ({
  modalContainer: {
    minWidth: 300,
    paddingTop: 15,
    top: "50%",
    left: "50%",
    transform: `translate(-50%, -50%)`,
    position: "absolute",
    backgroundColor: theme.palette.background.paper,
    borderRadius: "15px",
    boxShadow: theme.shadows[5],
  },
  txtContents: {
    padding: 20,
  },
  modalTitle: {
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    borderTopLeftRadius: "15px",
    borderTopRightRadius: "15px",
  },
  modalContent: {
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    marginTop: 20,
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: 100,
    borderTopLeftRadius: "15px",
    borderTopRightRadius: "15px",
  },
  modalFooter: {
    height: 50,
    backgroundColor: theme.palette.grey[200],
    borderBottomLeftRadius: "15px",
    borderBottomRightRadius: "15px",
    textAlign: "center",
  },
  modalButton: {
    height: "100%",
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    "&:first-child": {
      borderBottomLeftRadius: "15px",
    },
    "&:last-child": {
      borderBottomRightRadius: "15px",
    },
  },
}));
