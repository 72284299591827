import React, { useContext, useState } from "react";
import {
  Dialog,
  useTheme,
  useMediaQuery,
  IconButton,
  Typography,
  Backdrop,
  CircularProgress,
} from "@material-ui/core";

import { ConfigContext } from "../../AppContexts";
import useStyles from "./DetailDialogStyle";
import "./detaildialog.css";

interface IDetailDialog {
  imgSrc: string;
  open: boolean;
  onClose: () => void;
  footer?: React.ReactNode;
}

const DetailDialog: React.FC<IDetailDialog> = ({
  imgSrc,
  open,
  onClose,
  footer,
}) => {
  const {
    assets: { other_conversation_img },
  } = useContext(ConfigContext);
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("xl"));
  const [imageLoaded, setImageLoaded] = useState(false);

  return (
    <Dialog
      fullScreen={fullScreen}
      open={open}
      onClose={onClose}
      className={classes.contentContainer}
    >
      <div style={{ width: "50%", margin: "0 auto", flex: "0 0 auto" }}>
        <img
          src={imgSrc}
          className={classes.img}
          alt="상세설명"
          onLoad={() => setImageLoaded(true)}
        />
      </div>
      <div className={classes.footerContainer}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {imageLoaded && footer}
        </div>
      </div>

      <IconButton onClick={onClose} className={classes.closeButton}>
        <img
          src={other_conversation_img}
          className={classes.closeButtonIcon}
          alt="닫기"
        />
        <Typography className={classes.closeButtonText}>
          다른 대화하기
        </Typography>
      </IconButton>

      <Backdrop className={classes.backdrop} open={!imageLoaded}>
        <CircularProgress />
      </Backdrop>
    </Dialog>
  );
};

export default DetailDialog;
