import makeStyles from "../../utils/makeStyles";

export default makeStyles((theme) => ({
  root: {
    zIndex: theme.zIndex.drawer,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "rgba(0, 0, 0, 0.82)",
  },
  icon: {
    color: "#fff",
    fontSize: 30,
  },
  hashIcon: {
    width: "25px",
    height: "25px",
    borderRadius: "16px",
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    fontSize: "18px",
    fontWeight: 500,
    lineHeight: 1.4,
  },
  button: {
    minWidth: "270px",
    height: "7%",
    paddingLeft: "25px",
    paddingRight: "25px",
    "&:hover": {
      backgroundColor: theme.palette.secondary.main,
    },
  },
  buttonTextSecondary: {
    color: theme.palette.text.secondary,
  },
  contrastText: {
    color: theme.palette.primary.contrastText,
  },
  chatInput: {
    position: "absolute",
    bottom: 3,
    right: "16px",
    margin: "auto 0 4px 15px",
    width: "calc( 100% - 110px )",
  },
}));
