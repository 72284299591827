import React, { SetStateAction, useContext, useEffect, useState } from "react";
import {
  AppBar,
  IconButton,
  Typography,
  Collapse,
  Backdrop,
} from "@material-ui/core";
import { animateScroll } from "react-scroll";

import ChatInputForm from "../ChatInputForm";
import MenuCarousel, { ICardContent } from "../MenuCarousel";
import useStyles from "./InputBarStyle";
import gaTraceData from "../../utils/ga";
import { ProfileContext } from "../../AppContexts";

interface IDetailDialog {
  menus: ICardContent[];
  onCardClick: (r: any, c: any) => void;
  onSubmit: (s: string) => void;
  onInputChange?: (s: string) => void;
  onToggleExpanded?: (expanded: boolean | SetStateAction<boolean>) => void;
}

const InputBar: React.FC<IDetailDialog> = ({
  menus,
  onCardClick,
  onInputChange,
  onSubmit,
  onToggleExpanded,
}) => {
  const classes = useStyles();

  const { userProfile } = useContext(ProfileContext);
  const viewPort = { width: window.innerWidth, height: window.innerHeight };
  const [expanded, setExpanded] = useState(false);
  const [message, setMessage] = useState("");
  const BASE_ITEMS_PER_SCREEN = 8;
  const COLS = 4;

  useEffect(() => {
    setExpanded(true);
    setTimeout(() => setExpanded(false), 100);
  }, []);

  const _setExpanded = (expanded: SetStateAction<boolean>) => {
    setExpanded(expanded);
    if (onToggleExpanded) {
      onToggleExpanded(expanded);
    }
  };

  const _onCardClick = (r: any, c: any) => {
    onCardClick(r, c);
    _setExpanded(false);
  };

  // const getCardStyle = (i: number) => ({backgroundColor: i < COLS ? 'blue' : 'green'});
  const getCardClassName = (i: number) =>
    i < COLS ? classes.firstRowCards : classes.secondRowCards;

  const updateWindowDimensions = () => {
    /*
         캐러셀의 크기가 부모 및 부모의 부모 컴포넌트 크기에 의존함.
         아래 코드는 캐러셀의 부모 컴포넌트 사이즈를 알맞게 지정한 뒤 맨 아래로 스크롤 함.
         */
    // debounce(() => {
    //     setViewPort({ width: window.innerWidth, height: window.innerHeight });
    //     setTimeout(() => {
    //         animateScroll.scrollToBottom();
    //     }, 500);
    // }, 300);

    // 메뉴를 펼친채로 리사이즈를 하면 캐러셀 영역 높이설정이 올바르지 않아 임시로 닫음.
    setExpanded(false);
  };
  window.addEventListener("resize", updateWindowDimensions);

  const _onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputText = event.target.value;
    setMessage(inputText);

    if (onInputChange) {
      onInputChange(inputText);
    }
  };

  const _onSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setMessage("");
    if (message) {
      gaTraceData(userProfile, "질문", message, null);
      onSubmit(message);
    }
  };

  return (
    <AppBar
      position={expanded ? "relative" : "fixed"}
      className={classes.inputBarContainer}
      style={expanded ? { marginTop: -52, maxWidth: "100vw" } : {}}
    >
      <div className={classes.inputBar}>
        <IconButton
          color="secondary"
          aria-label="메뉴"
          className={classes.hashIconButton}
          onClick={() => _setExpanded((oldVal) => !oldVal)}
        >
          <Typography className={classes.hashIcon}>#</Typography>
        </IconButton>
        <ChatInputForm
          value={message}
          placeholder="질문을 입력하세요."
          onInputChange={_onInputChange}
          onFocus={() => _setExpanded(false)}
          onSubmit={_onSubmit}
        />
      </div>
      <Collapse
        in={expanded}
        timeout="auto"
        unmountOnExit
        className={classes.collapse}
        addEndListener={(node: HTMLElement) =>
          node.addEventListener(
            "transitionend",
            animateScroll.scrollToBottom,
            false
          )
        }
      >
        <MenuCarousel
          cardContents={menus}
          onCardClick={_onCardClick}
          cols={COLS}
          itemsPerScreen={
            viewPort.width < 1280
              ? BASE_ITEMS_PER_SCREEN
              : Math.floor(BASE_ITEMS_PER_SCREEN * 1.5)
          }
          getCardClassName={getCardClassName}
          category="바텀메뉴"
        />
      </Collapse>
      <Backdrop
        className={classes.backdrop}
        open={expanded}
        onClick={() => _setExpanded(false)}
      />
    </AppBar>
  );
};

export default InputBar;
