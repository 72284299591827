import makeStyles from "../../utils/makeStyles";

export default makeStyles((theme) => ({
  inputBarContainer: {
    top: "auto",
    bottom: 0,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: theme.palette.primary.light,
    boxShadow: "unset",
    width: "100%",
  },
  inputBar: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    width: "calc(100% - 20px)",
  },
  firstRowCards: {
    backgroundColor: theme.custom.carousel.palette.firstRow,
  },
  secondRowCards: {
    backgroundColor: theme.custom.carousel.palette.secondRow,
  },
  hashIconButton: {
    width: "30px",
    height: "30px",
    borderRadius: "20px",
    backgroundColor: theme.palette.secondary.main,
    textAlign: "center",
    lineHeight: 1.4,
    padding: 0,
    "&:hover": {
      backgroundColor: theme.palette.secondary.main,
    },
  },
  hashIcon: {
    color: theme.palette.secondary.contrastText,
    fontSize: 18,
    fontWeight: 500,
  },
  collapse: {
    width: "100%",
    backgroundColor: theme.palette.common.white,
  },
  backdrop: {
    zIndex: -2,
    backgroundColor: "rgba(0, 0, 0, 0)",
    root: {
      width: "100%",
    },
  },
}));
