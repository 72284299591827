const theme = {
  overrides: {
    carousel: {
      card0: {
        backgroundColor: "rgb(0, 96, 255)",
      },
      card1: {
        backgroundColor: "rgb(0, 96, 255)",
      },
      card2: {
        backgroundColor: "rgb(0, 96, 255)",
      },
      card3: {
        backgroundColor: "rgb(0, 96, 255)",
      },
      card4: {
        backgroundColor: "rgb(0, 96, 255)",
      },
      card5: {
        backgroundColor: "rgb(0, 96, 255)",
      },
    },
  },
  palette: {
    primary: {
      main: "rgb(255, 90, 13)",
      dark: "rgb(249, 13, 0)",
    },
    secondary: {
      main: "rgb(0, 0, 0)",
      light: "rgb(255, 176, 0)",
    },
    info: {
      main: "rgb(0, 143, 213)",
    },
    error: {
      main: "#d9e021",
    },
    text: {
      primary: "#fff",
      secondary: "#000",
    },
  },
  typography: {
    h1: {
      fontWeight: 700,
    },
    h2: {
      fontWeight: 700,
    },
    h3: {
      fontWeight: 700,
    },
    h4: {
      fontWeight: 700,
    },
    h5: {
      fontSize: "3vh",
      fontWeight: 700,
      lineHeight: "4vh",
    },
    h6: {
      fontSize: "2vh",
      lineHeight: 1.4,
      fontWeight: 600,
    },
  },
};

export default theme;
