import React from "react";
import { Typography } from "@material-ui/core";

import KakaoLogin from "../KakaoLogin";

const LoginMessage: React.FC = () => {
  return (
    <React.Fragment>
      <Typography>로그인 후 이용하실 수 있습니다.</Typography>
      <KakaoLogin />
    </React.Fragment>
  );
};

export default LoginMessage;
