import makeStyles from "../../utils/makeStyles";

export default makeStyles((theme) => ({
  root: {},
  loginButton: {
    width: "100%",
    padding: "15px 0",
    backgroundColor: "#fff",
    color: "rgb(19, 18, 1)",
    margin: "5px auto",
    borderRadius: 10,
  },
  loginButtonIcon: {
    width: 30,
    height: 30,
    marginRight: "10px",
    filter: "opacity(80%)",
  },
  loginButtonText: {
    fontWeight: 900,
    fontSize: "1em",
  },
}));
