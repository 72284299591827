import { makeStyles as _makeStyles } from "@material-ui/core/styles";
import { Theme as DefaultTheme } from "@material-ui/core/styles/createMuiTheme";
import {
  ClassNameMap,
  Styles,
  WithStylesOptions,
} from "@material-ui/styles/withStyles";
import { Omit } from "@material-ui/types";

import defaultTheme from "../themes/defaultTheme";

/**
 * `makeStyles` where the passed `styles` do not depend on props
 */
export default function makeStyles<
  Theme = DefaultTheme & { custom?: any },
  ClassKey extends string = string
>(
  style: Styles<Theme, {}, ClassKey> & { custom?: any },
  options?: Omit<WithStylesOptions<Theme>, "withTheme">
): (props?: any) => ClassNameMap<ClassKey>;

/**
 * `makeStyles` where the passed `styles` do depend on props
 */
export default function makeStyles<
  Theme = DefaultTheme & { custom?: any },
  Props extends {} = {},
  ClassKey extends string = string
>(
  styles: Styles<Theme, Props, ClassKey> & { custom?: any },
  options?: Omit<WithStylesOptions<Theme>, "withTheme">
): (props: Props) => ClassNameMap<ClassKey> {
  styles.custom = defaultTheme.custom;
  return _makeStyles(styles, options);
}
