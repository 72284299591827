import makeStyles from "../../utils/makeStyles";

export default makeStyles((theme) => ({
  root: theme.custom.carousel.root,
  gridContainer: {
    width: "85%",
    margin: "0 auto 40px auto",
    textAlign: "center",
    backgroundColor: theme.palette.common.white,
  },
  grid: {
    "&:after": {
      width: "100%",
      content: "",
      display: "block",
      paddingBottom: "100%",
    },
    ...theme.custom.carousel.grid,
  },
  card: {
    borderRadius: "1vmin",
    boxShadow: "none",
    ...theme.custom.carousel.card,
  },
  gridButton: {
    margin: "16px 16px 8px 16px",
    display: "inline-block",
    flexDirection: "column",
  },
  tableButton: {
    display: "inline-block",
  },
  buttonName: {
    color: theme.palette.text.primary,
    fontWeight: 600,
    fontSize: "2.95vmin",
    wordBreak: "keep-all",
    textAlign: "center",
  },
  tableContainer: {
    width: "100%",
    marginLeft: "50px",
    maxWidth: "80%",
    margin: "10px auto",
    boxShadow: "none",
    ...theme.custom.tableContainer,
  },
  table: {
    border: "none",
    ...theme.custom.table,
  },
  tableCell: {
    padding: 0,
    borderBottom: 0,
    ...theme.custom.tableCell,
  },
  tableFooter: {
    "& > tr > td": {
      padding: 10,
    },
  },
  footerText: {
    color: theme.palette.info.main,
    fontSize: "3.5vmin",
  },
  whiteBg: {
    backgroundColor: "#fff",
    borderRadius: "5vmin",
  },
  transparentBg: {
    backgroundColor: "transparent",
  },
  cardImage: {
    ...theme.custom.carousel.cardImage,
  },
  pagination: {
    "& > .swiper-pagination-bullet": {
      backgroundColor: "#464646",
    },
    "& > .swiper-pagination-bullet-active": {
      backgroundColor: "#f5821f",
    },
    ...theme.custom.carousel.pagination,
  },
  buttonNext: {
    color: theme.palette.grey[700],
    "&:after": {
      fontSize: "6vmin",
      fontWeight: 900,
    },
    ...theme.custom.carousel.buttonNext,
  },
  buttonPrev: {
    color: theme.palette.grey[700],
    "&:after": {
      fontSize: "6vmin",
      fontWeight: 900,
    },
    ...theme.custom.carousel.buttonPrev,
  },
}));
