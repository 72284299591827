import makeStyles from "../../utils/makeStyles";

export default makeStyles((theme) => ({
  root: {
    width: "100%",
    display: "flex",
    marginTop: 3,
    marginBottom: 3,
    "&:hover": {
      backgroundColor: theme.palette.secondary.light,
      color: theme.palette.secondary.main,
    },
    "&:focus > .MuiButton-label > .MuiBadge-root > .MuiBadge-badge": {
      backgroundColor: theme.palette.secondary.contrastText,
      color: theme.palette.secondary.main,
    },
    "&:focus > .MuiButton-label > h6": {
      color: theme.palette.secondary.contrastText,
    },
    "&:focus": {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.secondary.contrastText,
    },
  },
  badge: {},
  text: {
    flex: 1,
    marginLeft: "15px",
    marginRight: "10px",
    minWidth: "150px",
    fontSize: "14px",
  },
  ArrowIcon: {
    fontSize: "14px",
  },
}));
