import React, { useState, useContext } from "react";
import { IconButton, Typography } from "@material-ui/core";
import { API } from "aws-amplify";

import { ConfigContext, ProfileContext } from "../../AppContexts";
import useStyles from "./KakaoLoginStyle";
import KakaoImage from "../../images2/kakaoIcon.png";
import KakaoOtherImage from "../../images2/kakaoOtherIcon.png";
import TermsAndConditionsDialog from "../TermsAndConditionsDialog";

const KakaoLogin: React.FC<{ onLoginFailed?: (error: any) => void }> = ({
  onLoginFailed,
}) => {
  const { gatewayRoot, cid } = useContext(ConfigContext);
  const { setUserProfile } = useContext(ProfileContext);
  const classes = useStyles();
  const [showTermsDialog, setShowTermsDialog] = useState(false);
  let userProfile: any;
  const [tempUserProfile, setTempUserProfile] = useState(userProfile);
  const [kakaoLoginInfo, setKakaoLoginInfo] = useState({
    loginId: "",
    accessToken: "",
    refreshToken: "",
  });

  const kakaoLoginOnSuccess = (authObj: any) => {
    let apiName = "kakaoLoginApi";
    let path = "/login/kakao";
    let myInit = { body: authObj };

    API.post(apiName, path, myInit)
      .then((response) => {
        console.log("Kakao sign in response", response);
        const { access_token, refresh_token } = response.body;

        localStorage.setItem("wisecareKakaoRefreshToken", refresh_token);

        // @ts-ignore
        window.Kakao.API.request({
          url: "/v2/user/me",
          success: function (response: any) {
            console.log("Kakao user info", response);
            const { email, profile, birthday, gender } = response.kakao_account;
            const { nickname, thumbnail_image_url } = profile;
            userProfile = {
              nickname,
              profileImage: thumbnail_image_url,
              email,
              birthday,
              gender,
            };
            console.log("user profile:", userProfile);

            setTempUserProfile(userProfile);
            setKakaoLoginInfo({
              loginId: email,
              accessToken: access_token,
              refreshToken: refresh_token,
            });

            API.post("apiProxy", "/proxy/other/getUserLogin", {
              body: {
                apiRoot: gatewayRoot,
                loginId: email,
                accessToken: access_token,
                refreshToken: refresh_token,
                cid,
              },
            }).then((response) => {
              console.log("user exists?", response);
              const { resultCode } = response;
              if (resultCode === "200") {
                // 가입된 사용자면
                setUserProfile(userProfile);
              } else {
                console.log("userProfile", userProfile);
                setShowTermsDialog(true);
              }
            });
          },
          fail: function (error: any) {
            console.error("Failed to fetch Kakao user info", error);
            setUserProfile(null);
          },
        });
      })
      .catch((error) => {
        console.error("Auth API error", error);
        if (onLoginFailed) {
          onLoginFailed(error);
        }
      });
  };

  const login = (loginForm?: string) => () => {
    // @ts-ignore
    const loginFunc =
      loginForm === "other"
        ? window.Kakao.Auth.loginForm
        : window.Kakao.Auth.login;

    loginFunc({
      success: kakaoLoginOnSuccess,
      fail: function (error: Error) {
        console.error("Kakao login failure!", error);
        if (onLoginFailed) {
          onLoginFailed(error);
        }
      },
    });
  };

  const onAgree = () => {
    console.log("Sign in agreed", tempUserProfile);

    const { loginId, accessToken, refreshToken } = kakaoLoginInfo;
    API.post("apiProxy", "/proxy/other/userInitLogin", {
      body: { apiRoot: gatewayRoot, loginId, accessToken, refreshToken, cid },
    }).then(() => setUserProfile(tempUserProfile));
  };

  return (
    <React.Fragment>
      <div className={classes.root}>
        <IconButton
          className={classes.loginButton}
          onClick={login()}
          style={{ backgroundColor: "#fee500" }}
        >
          <img
            src={KakaoImage}
            className={classes.loginButtonIcon}
            alt="카카오 계정으로 로그인"
          />
          <Typography
            className={classes.loginButtonText}
            style={{ color: "#191919" }}
          >
            카카오 로그인
          </Typography>
        </IconButton>
        <IconButton
          className={classes.loginButton}
          onClick={login("other")}
          style={{ backgroundColor: "white" }}
        >
          <img
            src={KakaoOtherImage}
            className={classes.loginButtonIcon}
            style={{ filter: "opacity(50%)" }}
            alt="다른 카카오 계정으로 로그인"
          />
          <Typography className={classes.loginButtonText}>
            다른 카카오계정 로그인
          </Typography>
        </IconButton>
      </div>

      <TermsAndConditionsDialog
        open={showTermsDialog}
        onAgree={onAgree}
        onClose={() => setShowTermsDialog(false)}
      />
    </React.Fragment>
  );
};

export default KakaoLogin;
