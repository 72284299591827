import React, { useEffect, useState, useContext } from "react";
import { Container, Typography, Fab, Button } from "@material-ui/core";
import { API } from "aws-amplify";
import { useCookies } from "react-cookie";
import moment from "moment";

import { ConfigContext, ProfileContext, UIContext } from "../../AppContexts";
import useStyles from "./HomeStyle";
import MenuBar from "../../components/MenuBar";
import MessageList from "../../components/MessageList";
import { convertToChatMessage, IChoice } from "../../utils/messages";
import TutorialOverlay from "../../components/TutorialOverlay";
import DetailDialog from "../../components/DetailDialog";
import { ICardContent } from "../../components/MenuCarousel";
import PersonalDrawer from "../../components/PersonalDrawer";
import InputBar from "../../components/InputBar";
import { useHistory } from "react-router-dom";
import AlertModal from "../../components/AlertModal";
import TermsViewerDrawer from "../../components/TermsViewerDrawer";
import PostMessageButtons, {
  WRONG_ANSWER_TEXT,
} from "../../components/PostMessageButtons";
import gaTraceData from "../../utils/ga";

const OTHER_CONVERSATION_TEXT = "다른 대화하기";

interface IHome {
  greetingMessage: any;
  greetingMenu: any[];
  bottomMenu: any[];
  detailList: any[];
}

const Home: React.FC<IHome> = ({
  greetingMessage,
  greetingMenu,
  bottomMenu,
  detailList,
}) => {
  const {
    app_name,
    cs_name,
    cs_name_post_position,
    cid,
    apiRoot,
    gatewayRoot,
    ip,
    assets: { avatar_img, other_conversation_img },
  } = useContext(ConfigContext);

  const classes = useStyles();
  const history = useHistory();
  const [cookies] = useCookies(["tutorialCompleted"]);

  const [messageList, setMessageList] = useState(new Array<object>());
  const [, setMessageBreadcrumbList] = useState(new Array<string>());
  const [detailDialog, setDetailDialog] = useState({
    open: false,
    img: "",
    answerLink: "",
    answerLinkButtonText: "",
    answerLinkButtonStyle: {},
  });
  const [profileDialogOpen, setProfileDialogOpen] = useState(false);
  const [bottomMenus, setBottomMenus] = useState(new Array<ICardContent>());
  const [showFab, setShowFab] = useState(false);
  const [userProfile, setUserProfile] = useState();
  const [terms, setTerms] = useState({ open: false, url: "" });
  const [showGoodBye, setShowGoodBye] = useState(false);
  const [uiContext, setUIContext] = useState({
    showPostMessageComponents: true,
  });
  const [showKakaoError, setShowKakaoError] = useState(false);
  const [showAccountError, setShowAccountError] = useState(false);
  const [menuExpanded, setMenuExpanded] = useState(false);

  const initialMenuMessage = {
    messageType: "card",
    cardContents: greetingMenu.map((g) => ({
      imgSrc: g.menuImgUrl,
      response: g.question,
      loginRequired: g.loginRequired,
      card: g,
    })),
    cols: 2,
    layout: "table",
    footerMessage: `24시간 365일 '${cs_name}'${cs_name_post_position} 함께 합니다.`,
    category: "그리팅메뉴",
  };

  useEffect(
    () => {
      setBottomMenus(
        bottomMenu.map((b) => ({
          imgSrc: b.menuImgUrl,
          response: b.question,
          name: b.menuName,
          loginRequired: b.loginRequired,
          card: b,
        }))
      );
      const todayTimeStampMessage = {
        messageType: "reactNode",
        isSystemMessage: true,
        messageNode: (
          <div className={classes.todayTimestamp}>
            {moment(new Date()).format("YYYY년 M월 D일 dd요일")}
          </div>
        ),
      };
      // TODO 서버 API로 이동
      if (greetingMessage.answerInfo) {
        greetingMessage.answerInfo.rate = 100;
        greetingMessage.answerInfo.step = null;
        greetingMessage.answerInfo.isScenario = null;
      }
      console.log("initialMenuMessage", initialMenuMessage);
      console.log("greetingMessage", greetingMessage);
      printReceivedMessage([
        todayTimeStampMessage,
        greetingMessage,
        initialMenuMessage,
      ]);
    },
    // eslint-disable-next-line
    []
  );

  const onBottomMenuClick = (r: any, c: any) => {
    console.log("onBottomMenuClick", r, c);
    if (r) {
      printSentMessage(r);
      submitMessage(r);
    } else {
      printReceivedMessage({
        html: "죄송합니다. 알맞은 답변을 찾지 못했습니다.",
      });
      onEndScene();
    }
  };

  const onCardClick = (msg: string | undefined, c: any | undefined) => {
    console.log("onCardClick", msg, c);

    if (c && c.btnInfo && c.btnInfo[1].btnTitle === "answerCode") {
      const detailAnswer = detailList.find(
        (e) => e.answerCode === c.btnInfo[1].btnValue
      );
      setDetailDialog({
        open: true,
        img: detailAnswer.answerImgUrl,
        answerLink: "",
        answerLinkButtonText: "",
        answerLinkButtonStyle: {},
      });
    }

    if (msg) {
      printSentMessage(msg);
      submitMessage(msg);
    }
  };

  const _renderAfterCmp = (
    msgBox: any,
    sentMessage: string | undefined
  ) => () => {
    console.log("after cmp msg", msgBox);
    const { showInitialMenuButton, showReportWrongAnswerButton } = msgBox;

    return (
      <PostMessageButtons
        showInitialMenu={() => onSubmit("처음으로")}
        showInitialMenuButton={showInitialMenuButton}
        reportWrongAnswer={reportWrongAnswer}
        showReportWrongAnswerButton={showReportWrongAnswerButton}
        reqMessage={sentMessage}
        answerInfo={msgBox.answerInfo}
      />
    );
  };

  const deleteAccount = () => {
    // @ts-ignore
    const accessToken = window.Kakao.Auth.getAccessToken();
    const refreshToken = localStorage.getItem("wisecareKakaoRefreshToken");
    const { email } = userProfile;

    console.log("delete account", accessToken, refreshToken, email);

    API.post("apiProxy", "/proxy/other/userLoginExit", {
      body: { apiRoot: gatewayRoot, loginId: email, accessToken, refreshToken },
    })
      .then((response) => {
        if (response.resultCode === "200") {
          // @ts-ignore
          window.Kakao.API.request({
            url: "/v1/user/unlink",
            success: function (response: any) {
              console.log("Kakao unlink succeed", response);

              setUserProfile(null);
              setShowGoodBye(true);
            },
            fail: function (error: any) {
              console.error("Kakao unlink failed", error);

              setUserProfile(null);
              setShowKakaoError(true);
            },
          });
        } else {
          console.error(
            "회원탈퇴 실패",
            `curl -d '{"loginId":"${email}","accessToken":"${accessToken}","refreshToken":"${refreshToken}"}' ${gatewayRoot}`,
            response
          );

          setShowAccountError(true);
        }
      })
      .catch((e) => {
        console.error(
          "회원탈퇴 실패",
          `curl -d '{"loginId":"${email}","accessToken":"${accessToken}","refreshToken":"${refreshToken}"}' ${gatewayRoot}`,
          e
        );

        setShowAccountError(true);
      });
  };

  const openInModal = (c: IChoice) => {
    setTerms({ open: true, url: c.value });
  };

  const printReceivedMessage = (
    messageData: any | any[],
    sentMessage: string | undefined = undefined
  ) => {
    const _messageData = Array.isArray(messageData)
      ? messageData
      : [messageData];
    const newMessageList: object[] = [];
    let afterMessage: () => void = () => null;
    let _showFab = false;
    let _showAfterCmp = false;

    console.log("received message", messageData);

    _messageData.forEach((m) => {
      const { msgBox, detailImg, afterMessageCallback } = convertToChatMessage(
        m,
        detailList,
        {
          onEndScene,
          printSentMessage,
          submitMessage,
          submitMessageWithoutInference,
          onCardClick,
          showInitialMenu,
          deleteAccount,
          openInModal,
        },
        app_name,
        cs_name,
        userProfile ? userProfile.nickname : ""
      );
      const messageTime = new Date();
      newMessageList.push({
        ...msgBox,
        avatar: !msgBox.isSystemMessage && avatar_img,
        renderBeforeCmp:
          !msgBox.isSystemMessage &&
          (() => (
            <div className={classes.beforeMessageContainer}>
              <span className={classes.avatarName}>{cs_name}</span>
              <span
                className={classes.messageTimestamp}
                style={{ textAlign: "left" }}
              >
                {moment(messageTime).format("LT")}
              </span>
            </div>
          )),
        renderAfterCmp: _renderAfterCmp(msgBox, sentMessage),
      });
      if (detailImg) {
        const {
          answerLink,
          answerLinkButtonText,
          answerLinkButtonStyle,
        } = m.answerInfo;
        setDetailDialog({
          open: true,
          img: detailImg,
          answerLink,
          answerLinkButtonText,
          answerLinkButtonStyle,
        });
      }
      afterMessage = afterMessageCallback;
      _showFab = msgBox.showFab;
      _showAfterCmp =
        msgBox.showInitialMenuButton || msgBox.showReportWrongAnswerButton;
    });

    setShowFab(_showFab);
    setUIContext({ showPostMessageComponents: !!_showAfterCmp });
    console.log("SHOW POST MESSAGE", _showAfterCmp);
    setMessageList((oldMessageList) => [...oldMessageList, ...newMessageList]);
    afterMessage();
  };

  const onSubmit = async (message: string) => {
    printSentMessage(message);
    await submitMessage(message);
  };

  const printSentMessage = (msg: string) => {
    const messageTime = new Date();
    setMessageList((oldList) => [
      ...oldList,
      {
        position: "right",
        type: "text",
        text: msg,
        renderBeforeCmp: () => (
          <div
            className={classes.messageTimestamp}
            style={{ textAlign: "right" }}
          >
            {moment(messageTime).format("LT")}
          </div>
        ),
      },
    ]);

    setMessageBreadcrumbList((oldList) => [...oldList, msg]);
    setUIContext({ showPostMessageComponents: false });
    console.log("HIDE POST MESSAGE");
  };

  const submitMessage = (msg: string) => {
    console.log("message", apiRoot, cid, msg);

    return API.post("apiProxy", "/proxy/chat/message", {
      body: { apiRoot, cid, type: "text", msg, ip },
    })
      .then((responseMessage) => {
        console.log(
          "submit responseMessage =",
          JSON.stringify(responseMessage)
        );
        printReceivedMessage(responseMessage.data, msg);
      })
      .catch((e) => {
        console.error("Message error:", e);
        printReceivedMessage({
          html: "죄송합니다. 알맞은 답변을 찾지 못했습니다.",
        });
        onEndScene();
      });
  };

  const submitMessageWithoutInference = (msg: string) => {
    console.log("message without inference", apiRoot, cid, msg);

    return submitMessage(msg);

    // 추론 제외기능 임시제거
    // return API.post(
    //     'apiProxy',
    //     '/proxy/other/getQuetionInfo',
    //     {body: {apiRoot: gatewayRoot, displayName: msg}}
    // ).then(response => {
    //     console.log('question info response', response);
    //     return submitMessage(response.data.question);
    // });
  };

  const onEndScene = () => {
    console.log("onEndScene");
    return API.post("apiProxy", "/proxy/scene/end", { body: { apiRoot, cid } })
      .then((responseMessage) => {
        console.log("end scene responseMessage =", responseMessage);
        // const message = responseMessage.data.sysMsg;
        // message.isSystemMessage = true;
        // printReceivedMessage(message);
      })
      .catch((e) => {
        console.error("Message error:", e);
      });
  };

  const onHomeClick = () => {
    printSentMessage("처음으로");
    submitMessage("처음으로");
    gaTraceData(userProfile, "홈버튼", "홈버튼 클릭", null);
  };

  const showInitialMenu = () => {
    printReceivedMessage(initialMenuMessage);
  };
  const reportWrongAnswer = (req: string | undefined, answerInfo: any) => {
    const { qid_1, qid_2, qid_3, answer } = answerInfo;
    printSentMessage(WRONG_ANSWER_TEXT);

    API.post("apiProxy", "/proxy/other/incorrect", {
      body: {
        apiRoot: gatewayRoot,
        qustId1: qid_1,
        qustId2: qid_2,
        qustId3: qid_3,
        req,
        answer,
      },
    })
      .then((res) => {
        console.log("reported wrong answer", res);
        submitMessage(WRONG_ANSWER_TEXT);
      })
      .catch((e) => {
        console.error("Message error:", e);
      });
  };

  const onProfileQuestion = (q: string) => {
    printSentMessage(q);
    submitMessage(q);
    setProfileDialogOpen(false);
  };

  const otherConversation = () => {
    // TODO 서버 API로 이동 및 submitMessage
    printSentMessage(OTHER_CONVERSATION_TEXT);
    onEndScene();
    printReceivedMessage({
      notEncrypted: true,
      answerInfo: {
        itag: "",
        isScenario: "N",
        qid_3: "0",
        answer:
          "<p>다른 내용이 궁금하신가요?<br/>메뉴로 선택하시거나, 직접 질문해 주세요!</p>",
        rate: 99.99,
        step: "E",
      },
      answerJson: { answerCode: "", aType: "", aTypeLow: "" },
      mtype: "res",
      selectDataInfo: { selectDataInfoObj: [], type: "no", lIds: [] },
      status: "NORMAL",
    });
  };

  const closeDetailDialog = () =>
    setDetailDialog({
      img: "",
      open: false,
      answerLink: "",
      answerLinkButtonText: "",
      answerLinkButtonStyle: {},
    });
  const openAnswerLink = (link: string) => () => {
    closeDetailDialog();
    window.open(link, "_blank");
  };

  return (
    <ProfileContext.Provider value={{ userProfile, setUserProfile }}>
      <React.Fragment>
        <Container maxWidth={false} className={classes.rootContainer}>
          {!cookies.tutorialCompleted && <TutorialOverlay />}

          <MenuBar
            onHomeClick={onHomeClick}
            onProfileClick={() => setProfileDialogOpen(true)}
          />

          <div className={classes.messageListContainer}>
            <UIContext.Provider value={uiContext}>
              <MessageList
                className={classes.messageList}
                dataSource={messageList}
              />
            </UIContext.Provider>
          </div>

          {showFab && (
            <Fab
              variant="extended"
              className={`${classes.fab} ${
                menuExpanded
                  ? classes.fabOnMenuExpanded
                  : classes.fabOnMenuCollapsed
              }`}
              onClick={otherConversation}
            >
              <img
                src={other_conversation_img}
                className={classes.otherConversationIcon}
                alt="다른 대화하기"
              />
              <Typography className={classes.initialMenuButtonText}>
                {OTHER_CONVERSATION_TEXT}
              </Typography>
            </Fab>
          )}

          <InputBar
            menus={bottomMenus}
            onSubmit={onSubmit}
            onCardClick={onBottomMenuClick}
            onToggleExpanded={(expanded) => setMenuExpanded(expanded)}
          />

          <DetailDialog
            imgSrc={detailDialog.img}
            open={detailDialog.open}
            onClose={closeDetailDialog}
            footer={
              detailDialog.answerLinkButtonText && (
                <Button
                  className={classes.answerLinkButton}
                  style={detailDialog.answerLinkButtonStyle}
                  onClick={openAnswerLink(detailDialog.answerLink)}
                >
                  {detailDialog.answerLinkButtonText}
                </Button>
              )
            }
          />
          <PersonalDrawer
            anchor="right"
            open={profileDialogOpen}
            onClose={() => setProfileDialogOpen(false)}
            onQuestion={onProfileQuestion}
          />
        </Container>
      </React.Fragment>

      <TermsViewerDrawer
        open={terms.open}
        onClose={() => setTerms({ open: false, url: "" })}
        termsUrl={terms.url}
      />

      <AlertModal
        open={showGoodBye}
        onClose={() => history.go(0)}
        onConfirm={() => {
          setShowGoodBye(false);
          history.go(0);
        }}
        title="회원 탈퇴가 완료되었습니다."
        messages={[
          `그 동안 ${cs_name}서비스를`,
          "이용해주셔서 감사드리며",
          "더욱 좋은 서비스로 찾아뵙겠습니다.",
        ]}
      />

      <AlertModal
        open={showKakaoError}
        onClose={() => history.go(0)}
        onConfirm={() => {
          setShowKakaoError(false);
          history.go(0);
        }}
        title="회원 탈퇴가 완료되었습니다."
        messages={[
          "카카오 앱 연결 해제 중 오류가 발생했습니다.",
          '카카오 앱의 "연결된 서비스 관리"메뉴에서',
          "직접 해제해주세요.",
        ]}
      />

      <AlertModal
        open={showAccountError}
        onConfirm={() => {
          setShowAccountError(false);
        }}
        title="서비스 탈퇴 중 오류가 발생했습니다."
        messages={["잠시 후 다시 시도해주세요."]}
      />
    </ProfileContext.Provider>
  );
};

export default Home;
