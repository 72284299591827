import React, { useEffect, useState } from "react";
import { Route, Switch } from "react-router-dom";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import publicIp from "public-ip";
import { API } from "aws-amplify";
import { CookiesProvider } from "react-cookie";
import { Helmet } from "react-helmet";

import { ConfigContext } from "./AppContexts";
import Home from "./pages/Home";
import AnswerDetail from "./pages/AnswerDetail";
import ErrorPage from "./pages/ErrorPage";
import WiseDev from "./pages/WiseDev";

import "./App.css";

function App() {
  const SITE_CODE = process.env.REACT_APP_SITE_CODE;
  const ENGINE_ROOT = process.env.REACT_APP_ENGINE_ROOT;

  const [configContext, setConfigContext] = useState();
  const [homeParams, setHomeParams] = useState({
    greetingMessage: {},
    greetingMenu: [],
    bottomMenu: [],
    detailList: [],
  });
  const [loading, setLoading] = useState({ isLoading: true, loadingImg: "" });
  const [theme, setTheme] = useState();

  useEffect(() => {
    (async function startupRoom() {
      let loadingImg = "";
      try {
        const fetchIp = publicIp.v4();
        const fetchEngineInfo = API.post(
          "apiProxy",
          `/proxy/engine/info?site=${SITE_CODE}`,
          { body: { apiRoot: ENGINE_ROOT } }
        );
        const [ip, engineInfo] = await Promise.all([fetchIp, fetchEngineInfo]);
        console.log("engineInfo", ENGINE_ROOT, engineInfo);

        const {
          app_name,
          cs_name,
          cs_name_post_position,
          cs_name_english,
          theme_name,
          section_code,
          sort_code,
          assets,
          terms_and_conditions,
        } = engineInfo.app_info;
        setLoading({ isLoading: true, loadingImg: assets.loading_img });

        const _theme = require(`./themes/${theme_name}`).default;
        setTheme(
          _theme
            ? createMuiTheme(_theme)
            : createMuiTheme(require("./themes/defaultTheme").default)
        );

        const {
          url: gatewayUrl,
          port: gatewayPort,
        } = engineInfo.gw_server_info.port[0];
        const {
          url: webUrl,
          port: webPort,
        } = engineInfo.web_server_info.port[0];
        const apiRoot = `${webUrl}:${webPort}`;
        const gatewayRoot = `${gatewayUrl}:${gatewayPort}`;
        console.log("webRoot", apiRoot, "gatewayRoot", gatewayRoot);

        const fetchTagInfo = API.post("apiProxy", "/proxy/other/answer", {
          body: {
            apiRoot: gatewayRoot,
            aType: "tag_all",
            aTypeLow: "",
            answerCode: "",
          },
        })
          .then((res) => (res.resultCode === "400" ? { data: [] } : res))
          .catch(() => ({ data: [] }));
        const fetchStartupInfo = API.post("apiProxy", "/proxy/chat/startup", {
          body: { apiRoot, uid: "", ip },
        });
        const [{ data: tagInfos }, startupInfo] = await Promise.all([
          fetchTagInfo,
          fetchStartupInfo,
        ]);
        const { cid } = startupInfo.data;
        const titleInfo = tagInfos.find(
          (t: any) => t.answerCode === "tLoadingEnd"
        );
        console.log("tagInfos", tagInfos);

        await API.post("apiProxy", "/proxy/room/out", {
          body: { apiRoot, cid },
        });

        const fetchChatPage = API.post("apiProxy", "/proxy/chat/page", {
          body: { apiRoot, psize: -1, last: 0, cid },
        });
        const fetchSceneHoldList = API.post(
          "apiProxy",
          "/proxy/scene/hold/list",
          { body: { apiRoot, cid } }
        );
        const fetchRoomIn = API.post("apiProxy", "/proxy/room/in", {
          body: { apiRoot, cid },
        });
        const fetchSceneEnd = API.post("apiProxy", "/proxy/scene/end", {
          body: { apiRoot, cid },
        });
        const [
          {
            data: { 0: greetingMessage },
          },
          sceneHoldList,
          roomIn,
          sceneEnd,
        ] = await Promise.all([
          fetchChatPage,
          fetchSceneHoldList,
          fetchRoomIn,
          fetchSceneEnd,
        ]);

        console.log("greetingMessage", greetingMessage);
        // <p><img width="100%" height="100%" src="img/manage/getUploadImage/upload_image_20200730012508" onerror="this.src='http://vap.vap.ai:16007/manage/getUploadImage/upload_image_20200730012508'"><br></p>
        console.log(
          "greetingMessage img src",
          greetingMessage.answerInfo.answer.match(
            /onerror\s*=\s*['"]([^'"]+)['"]/
          )
        );
        // const greetingMessageImage1 = greetingMessage.answerInfo.answer.match(/src\s*=\s*['"]([^'"]+)['"]/)[1];
        const greetingMessageImage2 = greetingMessage.answerInfo.answer.match(
          /onerror\s*=\s*['"]this\.src\s*=\s*['"]([^'"]+)['"]/
        )[1];
        // console.log('greetingMessageImage1', greetingMessageImage1);
        // console.log('greetingMessageImage2', greetingMessageImage2);
        // preloadImage(greetingMessageImage1);
        preloadImage(greetingMessageImage2);
        console.log("sceneHoldList", sceneHoldList);
        console.log("roomIn", roomIn);
        console.log("sceneEnd", sceneEnd);

        const fetchGreetingMenu = API.post("apiProxy", "/proxy/other/menu", {
          body: { apiRoot: gatewayRoot, mType: "gMenu" },
        }).then((res) => (res.resultCode === "400" ? { data: {} } : res));
        const fetchBottomMenu = API.post("apiProxy", "/proxy/other/menu", {
          body: { apiRoot: gatewayRoot, mType: "bMenu" },
        }).then((res) => (res.resultCode === "400" ? { data: {} } : res));
        // const fetchNoticeSc = API.post('apiProxy', '/proxy/other/answer', {body: {apiRoot: gatewayRoot, answerCode: 'noticeSc'}}).then(res => res.resultCode === '400' ? {data: {}} : res);
        const fetchWorkTime = API.post("apiProxy", "/proxy/other/answer", {
          body: { apiRoot: gatewayRoot, answerCode: "workTime" },
        }).then((res) => (res.resultCode === "400" ? { data: {} } : res));
        const fetchDetailList = API.post("apiProxy", "/proxy/other/answer", {
          body: {
            apiRoot: gatewayRoot,
            employeeSectionCode: section_code,
            employeeSortCode: sort_code,
          },
        }).then((res) => (res.resultCode === "400" ? { data: [] } : res));
        const [
          { data: greetingMenu },
          { data: bottomMenu },
          {
            data: { 0: workTime },
          },
          { data: detailList },
        ] = await Promise.all([
          fetchGreetingMenu,
          fetchBottomMenu,
          fetchWorkTime,
          fetchDetailList,
        ]);
        // const [{data: greetingMenu}, {data: bottomMenu}, {data: {0: noticeSc}}, {data: medicalTime}, {data: detailList}] =
        //     await Promise.all([fetchGreetingMenu, fetchBottomMenu, fetchNoticeSc, fetchMedicalTime, fetchDetailList]);

        console.log("greetingMenu", greetingMenu);
        console.log("bottomMenu", bottomMenu);
        // console.log('noticeSc', noticeSc);
        console.log("workTime", workTime);
        console.log("detailList", detailList);

        greetingMessage.notEncrypted = true;
        greetingMessage.isGreeting = true;

        setConfigContext({
          cid,
          ip,
          apiRoot,
          gatewayRoot,
          app_name,
          cs_name,
          cs_name_post_position,
          cs_name_english,
          assets,
          terms_and_conditions,
          workTime,
          app_title: titleInfo
            ? titleInfo.aText
            : `${app_name} [${cs_name}] - AI직원 입니다.`,
        });
        setHomeParams({
          greetingMessage,
          greetingMenu,
          bottomMenu,
          detailList,
        });

        // 사용되는 이미지들을 적절한 순서 및 속도를 고려해 미리 로딩.
        const firstImages = (Object.values(assets) as string[]).concat(
          greetingMenu.map((g: any) => g.menuImgUrl)
        );
        Promise.all(firstImages.map(preloadImage))
          .then(() => {
            console.log("Asset and greeting images are loaded");
          })
          .catch((e) => {
            console.error("Some asset or greeting images are not loaded", e);
          })
          .then(() => {
            Promise.all(
              bottomMenu.map((b: any) => b.menuImgUrl).map(preloadImage)
            )
              .then(() => {
                console.log("Bottom menu images are loaded");
              })
              .catch((e) => {
                console.error("Some bottom menu images are not loaded", e);
              });

            // Promise.all(detailList.filter((d: any) => d.answerImgUrl).map((d: any) => d.answerImgUrl).map(preloadImage)).then(() => {
            //     console.log('Detail images loaded');
            // }).catch(e => {
            //     console.error('Some detail images are not loaded', e);
            // });
          });
      } catch (e) {
        console.error("Error on loading:", e);
      } finally {
        setLoading({ isLoading: false, loadingImg });
      }
    })();
  }, [ENGINE_ROOT, SITE_CODE]);

  if (loading.isLoading) {
    return (
      <div
        style={{
          position: "absolute",
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          backgroundSize: "auto 50%",
          cursor: "wait",
          backgroundImage: `url(${loading.loadingImg})`,
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundColor: "#fff",
        }}
      />
    );
  } else {
    const { app_name, cs_name, app_title } = configContext;

    return (
      <CookiesProvider>
        <Helmet>
          <title>{app_title}</title>
          <meta
            name="description"
            content={`${app_name} AI봇 ${cs_name}입니다.`}
          />
        </Helmet>

        <ConfigContext.Provider value={configContext}>
          <ThemeProvider theme={theme}>
            <div style={{ display: "flex" }}>
              <Route
                render={() => (
                  <main style={{ flex: 1 }}>
                    <Switch>
                      <Route
                        exact
                        path="/"
                        render={() => <Home {...homeParams} />}
                      />
                      <Route path="/answerDetail" component={AnswerDetail} />
                      <Route path="/wiseDev" component={WiseDev} />
                      <Route
                        render={(props) => (
                          <ErrorPage
                            {...props}
                            error={{
                              name: "404 Not Found",
                              message: `not found for ${window.location.href} `,
                            }}
                          />
                        )}
                      />
                    </Switch>
                  </main>
                )}
              />
            </div>
          </ThemeProvider>
        </ConfigContext.Provider>
      </CookiesProvider>
    );
  }
}

const preloadImage = (imgSrc: string) =>
  new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => resolve(imgSrc);
    img.onerror = () => reject();

    img.src = imgSrc;
  });

export default App;
