import makeStyles from "../../utils/makeStyles";

export default makeStyles((theme) => ({
  contentContainer: {
    width: "100%",
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#fff",
    position: "relative",
    boxShadow: "0",
    "&>div": {
      width: "100%",
    },
  },
  img: {
    width: "100%",
  },
  closeButton: {
    height: 35,
    padding: "5px 10px",
    position: "fixed",
    right: "2%",
    bottom: "2%",
    border: "2px solid black",
    boxShadow: "5px 5px 8px 1px rgba(0, 0, 0, .2)",
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    zIndex: theme.zIndex.modal + 2,
    "&:hover": {
      backgroundColor: theme.palette.common.black,
    },
  },
  closeButtonIcon: {
    color: theme.palette.secondary.main,
    marginRight: "5px",
    width: 25,
    height: 25,
  },
  closeButtonText: {
    fontWeight: 700,
    fontSize: "14px",
  },
  footerContainer: {
    minHeight: 60,
  },
  backdrop: {
    color: "#fff",
    width: "100%",
    height: "100vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: theme.zIndex.modal + 1,
  },
}));
