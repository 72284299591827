import makeStyles from "../../utils/makeStyles";

export default makeStyles((theme) => ({
  paper: {
    overflow: "hidden",
    backgroundColor: theme.palette.common.white,
    "&>div": {
      height: "100%",
    },
  },
  termsContent: {
    height: "100%",
  },
  buttonArea: {
    position: "fixed",
    right: "2%",
    zIndex: 1500,
    marginTop: "2%",
  },
  closeIconButton: {
    backgroundColor: theme.palette.grey[800],
    color: theme.palette.common.white,
    "&:hover": {
      backgroundColor: theme.palette.grey[600],
    },
  },
  closeIcon: {
    width: "35px",
    height: "35px",
  },
}));
