import React, { useState, useContext, useEffect } from "react";

import "./Faq.css";
import { ConfigContext, ProfileContext } from "../../AppContexts";
import gaTraceData from "../../utils/ga";
import { API } from "aws-amplify";

const Faq: React.FC<{ onQuestion: (q: string) => void }> = ({ onQuestion }) => {
  const {
    gatewayRoot,
    cs_name_english,
    assets: { avatar_big_img },
  } = useContext(ConfigContext);
  const { userProfile } = useContext(ProfileContext);
  const [questions, setQuestions] = useState();

  useEffect(() => {
    (async function _fetchQuestions() {
      const { data } = await API.post(
        "apiProxy",
        "/proxy/other/oftenQuestion",
        {
          body: {
            apiRoot: gatewayRoot,
            loginId: userProfile ? userProfile.email : "",
          },
        }
      ).then((res) => (res.resultCode === "400" ? { data: {} } : res));
      setQuestions(data);
    })();
  }, [gatewayRoot, userProfile]);

  const _onQuestion = (q: string) => {
    gaTraceData(userProfile, "마이메뉴", q, null);
    onQuestion(q);
  };

  return (
    <React.Fragment>
      <div className="kakao_faq">
        <div className="faq_contents">
          <p>
            <span>{cs_name_english}</span>에게{" "}
            <span className="bolder_700 txt_black">자주 물어본</span> 질문이에요
          </p>
          <div className="item">
            <div
              className="left_avatar"
              style={{
                background: `url(${avatar_big_img}) no-repeat center / contain`,
              }}
            ></div>
            <div className="right_question">
              {questions && (
                <div className="speach_bb">
                  <li onClick={() => _onQuestion(questions.question1)}>
                    {questions.question1}
                  </li>
                  <li onClick={() => _onQuestion(questions.question2)}>
                    {questions.question2}
                  </li>
                  <li onClick={() => _onQuestion(questions.question3)}>
                    {questions.question3}
                  </li>
                  <li onClick={() => _onQuestion(questions.question4)}>
                    {questions.question4}
                  </li>
                  <li onClick={() => _onQuestion(questions.question5)}>
                    {questions.question5}
                  </li>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Faq;
