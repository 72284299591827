import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import {
  IconButton,
  Drawer,
  AppBar,
  Toolbar,
  Modal,
  Typography,
  Button,
} from "@material-ui/core";
import { Close, ExitToApp } from "@material-ui/icons";

import { ConfigContext, ProfileContext } from "../../AppContexts";
import useStyles from "./PersonalDrawerStyle";
import kakaoBanner from "../../images2/kakao_banner.jpg";
import Profile from "../Profile";
import KakaoLogin from "../KakaoLogin";
import Schedules from "../Schedules";
import Faq from "../Faq";
import { API } from "aws-amplify";

import "./PersonalDrawer.css";

interface IDetailDialog {
  anchor: "left" | "right" | "top" | "bottom";
  open: boolean;
  onClose: () => void;
  onQuestion: (q: string) => void;
}

const PersonalDrawer: React.FC<IDetailDialog> = ({
  anchor,
  open,
  onClose,
  onQuestion,
}) => {
  const { gatewayRoot } = useContext(ConfigContext);
  const { userProfile, setUserProfile } = useContext(ProfileContext);
  const [showConfirm, setShowConfirm] = useState(false);
  const classes = useStyles();
  const history = useHistory();

  useEffect(
    () => {
      (async function _fetchUserProfile() {
        if (userProfile) {
          setUserProfile(userProfile);
        } else {
          await fetchUserProfile();
        }
      })();
    },
    // eslint-disable-next-line
    []
  );

  const fetchUserProfile = async () => {
    // @ts-ignore
    const accessToken = window.Kakao.Auth.getAccessToken();
    if (!accessToken) {
      return;
    }

    // @ts-ignore
    window.Kakao.API.request({
      url: "/v2/user/me",
      success: function (response: any) {
        console.log("Kakao user info", response);
        const { email, profile } = response.kakao_account;
        const { nickname, thumbnail_image_url } = profile;
        const fetchedUserProfile = {
          nickname,
          profileImage: thumbnail_image_url,
          email,
        };

        const refreshToken = localStorage.getItem("wisecareKakaoRefreshToken");

        API.post("apiProxy", "/proxy/other/getUserLogin", {
          body: {
            apiRoot: gatewayRoot,
            loginId: email,
            accessToken,
            refreshToken,
          },
        }).then((response) => {
          if (response.resultCode === "200") {
            setUserProfile(fetchedUserProfile);
          }
        });
      },
      fail: function (error: any) {
        console.error("Failed to fetch Kakao user info", error);
        setUserProfile(null);
      },
    });
  };

  const confirmLogout = () => {
    setShowConfirm(true);
  };

  const doLogout = () => {
    setShowConfirm(false);

    // @ts-ignore
    if (!window.Kakao.Auth.getAccessToken()) {
      console.log("Not logged in.");
      return;
    }
    // @ts-ignore
    window.Kakao.Auth.logout(function () {
      // @ts-ignore
      console.log("Kakao logout", window.Kakao.Auth.getAccessToken());

      // setUserProfile(null);
      history.go(0);
    });
  };

  return (
    <Drawer
      classes={{ paper: "personal-drawer-container" }}
      anchor={anchor}
      open={open}
      onClose={onClose}
    >
      <div style={{ position: "relative" }}>
        <AppBar position="static" className={classes.appbar}>
          <Toolbar className={classes.toolbar}>
            {userProfile ? (
              <Profile {...userProfile} />
            ) : (
              <div className={classes.loginContainer}>
                <Typography variant="h6" className={classes.loginTitle}>
                  카카오톡으로 간편하게 시작하세요!
                </Typography>
                <KakaoLogin />
              </div>
            )}
            <div className={classes.buttonArea}>
              {userProfile && (
                <IconButton
                  className={classes.closeIconButton}
                  onClick={confirmLogout}
                >
                  <ExitToApp className={classes.closeIcon} />
                </IconButton>
              )}
              <IconButton className={classes.closeIconButton} onClick={onClose}>
                <Close className={classes.closeIcon} />
              </IconButton>
            </div>
          </Toolbar>
        </AppBar>

        <Schedules />

        <Faq onQuestion={onQuestion} />

        <div className={classes.kakaoBanner}>
          <img
            src={kakaoBanner}
            alt="배너광고"
            className={classes.kakaoBannerImg}
          />
        </div>

        <Modal
          open={showConfirm}
          onClose={() => setShowConfirm(false)}
          disableEnforceFocus
          disableAutoFocus
        >
          <div className={classes.modalContainer}>
            <div className={classes.modalContent}>
              <Typography>로그아웃 하시겠습니까?</Typography>
            </div>
            <div className={classes.modalFooter}>
              <Button
                onClick={() => setShowConfirm(false)}
                className={classes.modalButton}
              >
                취소
              </Button>
              <Button onClick={doLogout} className={classes.modalButton}>
                로그아웃
              </Button>
            </div>
          </div>
        </Modal>
      </div>
    </Drawer>
  );
};

export default PersonalDrawer;
