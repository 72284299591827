import React from "react";
import { Button, Badge, Typography } from "@material-ui/core";
import { ArrowForwardIosRounded } from "@material-ui/icons";

import useStyles from "./ChoiceButtonStyle";
import { IChoice } from "../../utils/messages";

interface IMenuBar {
  choice: IChoice;
  badgeContent: React.ReactNode;
  onSelectChoice: (choice: IChoice) => void;
}

const ChoiceButton: React.FC<IMenuBar> = ({
  choice,
  badgeContent,
  onSelectChoice,
}) => {
  const classes = useStyles();

  return (
    <React.Fragment>
      <Button
        variant="outlined"
        onClick={() => onSelectChoice(choice)}
        className={classes.root}
      >
        <Badge
          badgeContent={badgeContent}
          color="secondary"
          className={classes.badge}
        />
        <Typography align="left" className={classes.text}>
          {choice.text}
        </Typography>
        <ArrowForwardIosRounded className={classes.ArrowIcon} />
      </Button>
    </React.Fragment>
  );
};

export default ChoiceButton;
