import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import "./globals";

import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import Amplify from "aws-amplify";
import moment from "moment";
import "moment/locale/ko";
import ReactGA from "react-ga";
import TagManager from "react-gtm-module";

import config from "./aws-exports";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

import "./index.css";

const { REACT_APP_GA_TRACKING_ID, REACT_APP_GTM_ID } = process.env
if (REACT_APP_GA_TRACKING_ID && REACT_APP_GTM_ID) {
  ReactGA.initialize(REACT_APP_GA_TRACKING_ID);
  TagManager.initialize({ gtmId: REACT_APP_GTM_ID });
}

moment().locale("ko");

Amplify.configure(config);

// @ts-ignore
window.Kakao.init(process.env.REACT_APP_KAKAO_APP_KEY); // JS key
// @ts-ignore
console.log("Kakao initialized", window.Kakao.isInitialized());

const Index = (
  <Router>
    <App />
  </Router>
);

ReactDOM.render(Index, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
