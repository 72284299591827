import React, { useState, useEffect } from "react";
import { Drawer, IconButton } from "@material-ui/core";
import { Close } from "@material-ui/icons";

import useStyles from "./TermsViewerDrawerStyle";

interface IDetailDialog {
  anchor?: "left" | "right" | "top" | "bottom";
  size?: string | number;
  borderRadius?: string | number;
  open: boolean;
  onClose: () => void;
  termsUrl: string;
}

const TermsViewerDrawer: React.FC<IDetailDialog> = ({
  anchor = "bottom",
  size = "80%",
  borderRadius = "5vmin",
  open,
  onClose,
  termsUrl,
}) => {
  console.log("TermsViewerDrawer url", termsUrl);

  const classes = useStyles();
  const [termsHtml, setTermsHtml] = useState();
  const paperStyle = {
    width: anchor === "left" || anchor === "right" ? size : "100%",
    height: anchor === "top" || anchor === "bottom" ? size : "100%",
    borderTopLeftRadius:
      anchor === "bottom" || anchor === "right" ? borderRadius : 0,
    borderTopRightRadius:
      anchor === "bottom" || anchor === "left" ? borderRadius : 0,
    borderBottomLeftRadius:
      anchor === "top" || anchor === "right" ? borderRadius : 0,
    borderBottomRightRadius:
      anchor === "top" || anchor === "left" ? borderRadius : 0,
  };

  useEffect(() => {
    (async function fetchTerms() {
      const response = await fetch(termsUrl).then((response) =>
        response.text()
      );
      setTermsHtml(response);
    })();
  }, [termsUrl]);

  return (
    <Drawer
      anchor={anchor}
      open={open}
      onClose={onClose}
      PaperProps={{ style: paperStyle, className: classes.paper }}
    >
      <div className={classes.termsViewerContainer}>
        <div className={classes.buttonArea}>
          <IconButton
            size="small"
            className={classes.closeIconButton}
            onClick={onClose}
          >
            <Close className={classes.closeIcon} />
          </IconButton>
        </div>
        <div
          dangerouslySetInnerHTML={{ __html: termsHtml }}
          className={classes.termsContent}
        />
      </div>
    </Drawer>
  );
};

export default TermsViewerDrawer;
