import React, { useContext } from "react";
import {
  ButtonBase,
  Card,
  Grid,
  Typography,
  Paper,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableFooter,
} from "@material-ui/core";
import Swiper from "react-id-swiper";
import "swiper/css/swiper.css";
import "./swiper.css";

import { ProfileContext } from "../../AppContexts";
import useStyles from "./MenuCarouselStyle";
import gaTraceData from "../../utils/ga";

export interface ICardContent {
  imgSrc: string;
  name?: string;
  response?: string;
  card: any;
  loginRequired?: boolean;
}
interface ICardMessage {
  cardContents: Array<ICardContent>;
  layout?: "grid" | "table";
  footerMessage?: string;
  onCardClick: (msg: string | undefined, card: any | undefined) => void;
  getCardStyle?: (index: number) => object;
  getCardClassName?: (index: number) => string;
  itemsPerScreen?: number;
  cols?: number;
  spacing?: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10;
  category?: string;
}

const MenuCarousel: React.FC<ICardMessage> = ({
  cardContents,
  layout = "grid",
  onCardClick,
  getCardStyle,
  getCardClassName,
  itemsPerScreen = 6,
  cols = 3,
  footerMessage,
  category = "시나리오",
  spacing,
}) => {
  const { userProfile } = useContext(ProfileContext);
  const isLoggedIn = !!userProfile;
  const classes = useStyles();

  const filteredCards = cardContents.filter(
    (c) => !c.loginRequired || (!!c.loginRequired && isLoggedIn)
  );
  let screenItems: ICardContent[][] = [];
  for (let i = 0, j = filteredCards.length; i < j; i += itemsPerScreen) {
    const tempArray = filteredCards.slice(i, i + itemsPerScreen);
    screenItems.push(tempArray);
    // screenItems = [...screenItems, tempArray];
  }
  console.log("screenItems", screenItems);

  const params =
    filteredCards.length > itemsPerScreen
      ? {
          pagination: {
            el: `.swiper-pagination.${classes.pagination}`,
            type: "bullets",
            clickable: true,
          },
          navigation: {
            nextEl: `.swiper-button-next.${classes.buttonNext}`,
            prevEl: `.swiper-button-prev.${classes.buttonPrev}`,
          },
          spaceBetween: 30,
        }
      : {
          noSwiping: true,
        };

  const _onCardClick = (msg: string, card: any, category: string) => {
    console.log("MenuCarousel onCardClick", userProfile, category, msg);
    gaTraceData(userProfile, category, msg, card);
    onCardClick(msg, card);
  };

  return (
    <React.Fragment>
      <Swiper {...params}>
        {screenItems.map((items, i) =>
          layout === "grid" ? (
            <div key={`grid-${i}`}>
              <GridLayout
                classes={classes}
                items={items}
                cols={cols}
                onCardClick={_onCardClick}
                getCardStyle={getCardStyle}
                getCardClassName={getCardClassName}
                category={category}
                spacing={spacing}
              />
            </div>
          ) : (
            <div key={`table-${i}`}>
              <TableLayout
                classes={classes}
                items={items}
                cols={cols}
                onCardClick={_onCardClick}
                footerMessage={footerMessage}
                category={category}
              />
            </div>
          )
        )}
      </Swiper>
    </React.Fragment>
  );
};

const GridLayout: React.FC<{
  classes: any;
  items: ICardContent[];
  cols: number;
  onCardClick: (msg: string, card: any, category: string) => void;
  getCardStyle?: (index: number) => object;
  getCardClassName?: (index: number) => string;
  category: string;
  spacing?: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10;
}> = ({
  classes,
  items,
  cols,
  onCardClick,
  getCardStyle,
  getCardClassName,
  category,
  spacing,
}) => {
  return (
    <Grid container className={classes.gridContainer} spacing={spacing}>
      {items.map(({ imgSrc, name, response, card }, i) => (
        <Grid
          key={`grid-${i}`}
          className={classes.grid}
          item
          xs={3}
          lg={2}
          xl={2}
        >
          <ButtonBase
            onClick={() => onCardClick(response || "", card, category)}
            className={classes.gridButton}
          >
            <Card
              className={`${classes.card} ${
                getCardClassName ? getCardClassName(i) : ""
              }`}
              style={getCardStyle && getCardStyle(i)}
            >
              <img src={imgSrc} alt={name} className={classes.cardImage} />
            </Card>
          </ButtonBase>
          {name && (
            <Typography className={classes.buttonName}>{name}</Typography>
          )}
        </Grid>
      ))}
    </Grid>
  );
};

const TableLayout: React.FC<{
  classes: any;
  items: ICardContent[];
  cols: number;
  onCardClick: (msg: string, card: any, category: string) => void;
  footerMessage?: string;
  category: string;
}> = ({ classes, items, cols, onCardClick, footerMessage, category }) => {
  const _items = new Array<Array<ICardContent>>();
  while (items.length) {
    _items.push(items.splice(0, cols));
  }

  return (
    <TableContainer component={Paper} className={classes.tableContainer}>
      <Table className={classes.table}>
        <TableBody>
          {_items.map((row, i) => (
            <TableRow key={`table-row-${i}`} className={classes.tableRow}>
              {row.map(({ imgSrc, name, response, card }, j) => (
                <TableCell
                  key={`table-cell-${i}-${j}`}
                  component="td"
                  scope="row"
                  className={classes.tableCell}
                >
                  <ButtonBase
                    onClick={() => onCardClick(response || "", card, category)}
                    className={classes.tableButton}
                  >
                    <img
                      src={imgSrc}
                      alt={name}
                      className={classes.cardImage}
                    />
                    {name && (
                      <Typography className={classes.buttonName}>
                        {name}
                      </Typography>
                    )}
                  </ButtonBase>
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
        {footerMessage && (
          <TableFooter className={classes.tableFooter}>
            <TableRow>
              <TableCell
                component="td"
                scope="row"
                className={classes.tableCell}
                colSpan={cols}
              >
                <Typography className={classes.footerText}>
                  {footerMessage}
                </Typography>
              </TableCell>
            </TableRow>
          </TableFooter>
        )}
      </Table>
    </TableContainer>
  );
};

export default MenuCarousel;
