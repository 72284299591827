import React, { useContext } from "react";
import { Search } from "@material-ui/icons";

import { ProfileContext } from "../../AppContexts";
import { IChoice, ISimilarQuestion } from "../../utils/messages";
import ChoiceButton from "../ChoiceButton";
import LoginMessage from "./LoginMessage";

interface IDefaultMessage {
  html?: string;
  messageNode?: React.ReactNode;
  similarQuestions?: ISimilarQuestion[];
  onSelectChoiceWithoutInference: (choice: IChoice) => void;
  loginRequired?: boolean;
}

const DefaultMessage: React.FC<IDefaultMessage> = ({
  html,
  messageNode,
  similarQuestions = [],
  onSelectChoiceWithoutInference,
  loginRequired,
}) => {
  const isLoggedIn = !!useContext(ProfileContext).userProfile;
  console.log("DEFAULT message login required", loginRequired);
  console.log("DEFAULT message similar questions", similarQuestions);

  if (loginRequired && !isLoggedIn) {
    return <LoginMessage />;
  }

  return (
    <React.Fragment>
      {html && <div dangerouslySetInnerHTML={{ __html: html }} />}
      {messageNode}
      {similarQuestions.length > 0 && (
        <div>
          {(html || messageNode) && (
            <hr
              style={{
                backgroundColor: "#eeeeee",
                height: 1,
                border: 0,
                margin: "15px 0",
              }}
            ></hr>
          )}
          <span>
            <Search className="search-icon" /> 혹시 이런 내용이 궁금하셨나요?
          </span>
          <div className="rce-mbox-buttons">
            {similarQuestions.map(({ displayName }, i) => (
              <ChoiceButton
                key={`similar-question-${i}`}
                choice={{ text: displayName }}
                badgeContent={String.fromCharCode(i + "A".charCodeAt(0))}
                onSelectChoice={onSelectChoiceWithoutInference}
              />
            ))}
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default DefaultMessage;
