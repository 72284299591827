const theme = {
  custom: {
    carousel: {
      cardImage: {
        width: "100%",
        height: "auto",
      },
      outlineStyle: {
        border: "1px solid #ac8c59",
      },
      grid: {},
      card: {},
      palette: {
        firstRow: "#bf8726",
        secondRow: "#fdb813",
      },
      pagination: {},
      buttonNext: {},
      buttonPrev: {},
    },
    menuBar: {
      justifyContent: "center",
      height: 48,
      backgroundColor: "#102ca1",
      boxShadow: "none",
    },
    tableContainer: {
      backgroundColor: "#fff",
      border: "2px solid #a98a54",
      borderRadius: "3vmin",
      boxSizing: "border-box",
    },
    table: {
      "& tbody > tr:first-child": {
        "& > td": {
          borderTop: 0,
        },
      },
      "& tbody > tr:last-child": {
        borderBottom: 0,
        "& > td": {
          borderBottom: 0,
        },
      },
    },
    tableRow: {},
    tableCell: {
      border: "2px solid #e1d1ba",
      textAlign: "center",
      "&:first-child": {
        borderLeft: 0,
      },
      "&:last-child": {
        borderRight: 0,
      },
      "& > button": {
        padding: "5vmin",
      },
    },
    message: {
      beforeContainer: {
        marginLeft: "10px",
      },
      afterContainer: {
        margin: "5px 0",
        height: "35px",
      },
      todayTimestamp: {
        margin: "5px 0",
        fontSize: 14,
        color: "#1a1a1a",
        fontWeight: 600,
      },
      messageTimestamp: {
        fontSize: 11,
        color: "#1b1b1b",
        width: "100%",
        verticalAlign: "middle",
      },
    },
    avatarName: {
      fontSize: 13,
      color: "#000",
      fontWeight: 700,
      display: "inline-block",
      verticalAlign: "middle",
      marginRight: 5,
    },
    button: {
      wrong: {
        fontWeight: 600,
        color: "red",
      },
    },
  },
  palette: {
    primary: {
      main: "#102ca1",
      light: "#e5eaf3",
      contrastText: "#fff",
    },
    secondary: {
      main: "#ff7800",
      light: "#f4e8d2",
      contrastText: "#fff",
    },
    info: {
      main: "#a98a54",
      light: "#e1d1ba",
      dark: "rgb(54, 38, 12)",
    },
    error: {
      main: "#d9e021",
      dark: "#464646",
      light: "#fff",
    },
    home: {
      main: "#fff",
    },
    text: {
      secondary: "rgba(0, 0, 0, 0.62)",
    },
  },
  typography: {
    h1: {
      fontWeight: 700,
    },
    h2: {
      fontWeight: 700,
    },
    h3: {
      fontWeight: 700,
    },
    h4: {
      fontSize: "3.5vh",
      fontWeight: 700,
    },
    h5: {
      fontSize: "3vh",
      fontWeight: 700,
      lineHeight: "4vh",
    },
    h6: {
      fontSize: "2vh",
      lineHeight: 1.4,
      fontWeight: 600,
    },
    subtitle1: {
      fontSize: "2.5vh",
      fontWeight: 900,
    },
    subtitle2: {
      fontSize: "2.5vh",
      fontWeight: 700,
    },
  },
  overrides: {
    MuiInputBase: {
      input: {
        color: "currentColor",
        height: "auto",
        padding: "0",
        // fontWeight:'normal',
        "&::-webkit-input-placeholder": {
          color: "#dbdbdb",
        },
      },
    },
    MuiButton: {
      root: {
        borderRadius: "100px",
      },
      outlined: {
        border: "1px solid #e4e4e4",
      },
    },
    MuiIconButton: {
      root: {
        borderRadius: "100px",
        fontSize: "1rem",
      },
    },

    PrivateSwitchBase: {
      root: {
        padding: 0,
      },
    },
  },
};

export default theme;
