/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-northeast-2",
    "aws_cognito_identity_pool_id": "ap-northeast-2:f29a3d53-4768-4f4d-bd02-c25f245dbe87",
    "aws_cognito_region": "ap-northeast-2",
    "aws_user_pools_id": "ap-northeast-2_5tevvdtr3",
    "aws_user_pools_web_client_id": "3sib92v0buv7fcu8n2ha1iejeo",
    "oauth": {},
    "aws_cloud_logic_custom": [
        {
            "name": "kakaoLoginApi",
            "endpoint": "https://i8ckpov703.execute-api.ap-northeast-2.amazonaws.com/prod",
            "region": "ap-northeast-2"
        },
        {
            "name": "apiProxy",
            "endpoint": "https://q1k78y7xd8.execute-api.ap-northeast-2.amazonaws.com/prod",
            "region": "ap-northeast-2"
        }
    ],
    "aws_user_files_s3_bucket": "iconstorage134924-dev",
    "aws_user_files_s3_bucket_region": "ap-northeast-2"
};


export default awsmobile;
