import React, { useContext } from "react";
import { Typography } from "@material-ui/core";
import moment from "moment";

import { ConfigContext } from "../../AppContexts";
import useStyles from "./SchedulesStyle";
import { EventNote } from "@material-ui/icons";

const Schedules: React.FC = () => {
  const classes = useStyles();
  const { workTime } = useContext(ConfigContext);
  const todayStr = moment(new Date()).format("YYYY년 M월 D일 (dd)");
  const todayWorkTimeStr = workTime.btnInfo[new Date().getDay() - 1].btnValue;
  const defaultLaunchTimeStr = workTime.btnInfo[8].btnValue;
  const todayLaunchTime = workTime.btnInfo[9 + new Date().getDay() - 1];
  const launchTimeStr = todayLaunchTime
    ? todayLaunchTime.btnValue
    : defaultLaunchTimeStr;

  return (
    <React.Fragment>
      <div className={classes.root}>
        <div className={classes.header}>
          <EventNote className={classes.headerIcon} />
          <Typography variant="h6" className={classes.headerText}>
            진료시간
          </Typography>
        </div>
        <div className={classes.content}>
          <span>
            {todayStr} {todayWorkTimeStr}
          </span>
          <span>{launchTimeStr && `점심시간: ${launchTimeStr}`}</span>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Schedules;
